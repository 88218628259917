/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faRemove, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { addReport, deleteReport } from "../../../../actions/Layers/actions";
import { Layer } from "../../../../actions/Layers/constants";
import { getNotifications } from "../../../../actions/Mailbox/actions";
import { MailboxState } from "../../../../actions/Mailbox/reducer";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { FormField, FormFileSelectFieldReadFormat } from "../../../../actions/Tenants/config/constantsTyped";
import { MimeType, ReportType } from "../../../../constants/misc";
import { RootState } from "../../../../reducers";
import { format, getEnumLabel } from "../../../../utils";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";
import AttachmentLink from "../../../Helper/AttachmentLink";
import AvosModal, { ConfirmationModal, ModalResult } from "../../../Helper/Modal";
import { truncateText } from "../../Layers/Layer/Report/utils";

interface NotificationReportProps {
    layer: Layer | undefined;
}

function MailedReports({ layer }: NotificationReportProps) {
    const notifications = useSelector<RootState, MailboxState["items"]>((state) => state.mailbox.items).results;
    const notifsWithReport = notifications?.filter((notification) => notification.attributes.report);
    const dispatch = useDispatch();

    useEffect(() => {
        if (layer?.id) {
            dispatch(getNotifications({ limit: 25, offset: 0, layer_id: layer.id }));
        }
    }, [layer?.id]);

    return notifsWithReport && notifsWithReport.length > 0 ? <div className="pt-4">
        <p>The reports mailed to supplier</p>
        {
            notifsWithReport.map((notif: any, index: number) => {
                const attachment_url = notif.attachment_urls.find((url) => url.includes(notif.attributes.report.blob_name));
                if (!attachment_url) {
                    console.warn("Attachment not found for notification", notif);
                    return null;
                }
                const date = format(notif.created);
                return <MetaRow key={index} label={notif.attributes.report.type} value={<AttachmentLink url={attachment_url} label={date} />} />;
            })
        }
    </div> : null;
}

const reportUploadModalFields: FormField[] = [
    {
        name: "reportType",
        label: "Document type",
        type: "single-select",
        mandatory: true,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        options: Object.entries(ReportType).filter(([_, value]) => value === ReportType.Temperature) // Only temperature reports are allowed for now
            .map(([label, value]) => ({ label, value })),
    },
    {
        name: "file",
        label: "Select file",
        type: "file-select",
        mandatory: true,
        accept: [MimeType.Pdf],
        readFormat: FormFileSelectFieldReadFormat.Base64,
    },
];

interface ModalObject {
    reportType: string;
    file: {
        name: string;
        content: string;
    };
}

export const getReports = (layer: Layer | undefined): { [reportType: string]: Array<[string, string]> } => {
    if (!layer) return {};
    const allReports = Object.entries(layer).filter(([key]) => key.startsWith("report_"));
    const groupedReports = allReports.reduce((acc, [key, value]) => {
        const reportType = key.split("_")[1];
        acc[reportType] = [...(acc[reportType] || []), [key, value]];
        return acc;
    }, {});
    return groupedReports;
};

export function getBlobNameFromUrl(url) {
    // Find the starting position of "/reports/"
    const start = url.indexOf("/reports/");

    // Find the ending position, which is the position of "?" in the URL
    const end = url.indexOf("?", start);

    // If the "?" is not found, use the end of the string
    const endPosition = end !== -1 ? end : url.length;

    // Extract and return the substring between start and endPosition
    return url.substring(start + 1, endPosition); // start + 1 to remove the leading '/'
}

function ReportExplorer() {
    const config = useConfig();
    const dispatch = useDispatch();
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const [reportSelectModalOpen, setReportSelectModalOpen] = useState(false);
    const [reportDeleteModalOpen, setReportDeleteModalOpen] = useState(false);
    const [modalObject, setModalObject] = useState<ModalObject>({ reportType: "", file: { name: "", content: "" } });
    const [reportToDelete, setReportToDelete] = useState({
        blob_url: "",
        meta_key: "",
    });
    const reports = getReports(layer);
    const onUploadClick = () => {
        setReportSelectModalOpen(true);
    };
    const onReportSelectModalClose = async (result: ModalResult) => {
        if (result === ModalResult.Cancel) {
            setReportSelectModalOpen(false);
        } else if (metaFormIsValid(reportUploadModalFields, modalObject)) {
            if (result === ModalResult.Save && layer?.id) {
                dispatch(addReport(modalObject.reportType, { name: modalObject.file.name, content: modalObject.file.content }, layer.id));
            }
            setReportSelectModalOpen(false);
        }
    };
    const setValue = (name: string, value: any) => {
        setModalObject({ ...modalObject, [name]: value });
    };
    const onDeleteClick = (blob_url: string, meta_key: string) => {
        setReportToDelete({
            blob_url,
            meta_key,
        });
        setReportDeleteModalOpen(true);
    };
    const onDeleteConfirm = () => {
        setReportDeleteModalOpen(false);
        if (layer?.id) {
            const blob_name = getBlobNameFromUrl(reportToDelete.blob_url);
            dispatch(deleteReport(reportToDelete.meta_key, blob_name, layer.id));
        }
    };

    return <div className="pt-4">
        <AvosModal isOpen={reportSelectModalOpen} onClose={onReportSelectModalClose} header="Upload Document" size="l">
            <MetaForm meta={reportUploadModalFields} setValue={setValue} object={modalObject} config={config} />
        </AvosModal>
        <ConfirmationModal isOpen={reportDeleteModalOpen} onClose={() => setReportDeleteModalOpen(false)} onConfirm={onDeleteConfirm} header="Delete Document" size="m">
            Are you sure you want to delete this document?
        </ConfirmationModal>
        <div className="d-flex justify-content-between">
            <label className="pe-2">Documents</label>
            <Button color="secondary" outline className="text-nowrap" size="sm" onClick={onUploadClick}> Upload <FontAwesomeIcon icon={faUpload} /></Button>
        </div>
        <div className="pt-2" >
            {Object.entries(reports).map(([reportType, reports]) => {
                return <div key={reportType}>
                    <MetaRow label={getEnumLabel(ReportType, reportType) || "Unknown"} value={<div>
                        {reports.map(([key, url], index) => {
                            const target = `${reportType}_${index}`;
                            return (<div key={index} className="d-flex justify-content-between align-items-center">
                                <AttachmentLink url={url} label={truncateText(key.replace(`report_${reportType}_`, ""), 20, 22)} id={target} />
                                <Button style={{
                                    border: "none",
                                    color: "red",
                                    fontWeight: "bold",
                                }} className="ms-2" outline size="md" onClick={() => onDeleteClick(url, key)}><FontAwesomeIcon icon={faRemove} /></Button>
                            </div>);
                        })}
                    </div>} />
                </div>;
            })}
        </div>
    </div>;
}

function MetaRow({ label, value }) {
    return (
        <div className="d-sm-flex py-1 justify-content-between" css={css`width: 100%;`}>
            <div className="text-muted pe-2" css={css`flex: 0 0 50%;`}>
                {label}
            </div>
            <div className="text-capitalize" css={css`flex: 1;`}>
                {value}
            </div>
        </div>
    );
}

export default function LayerReportsView({ layer }: { layer: Layer | undefined }) {
    return <div>
        <ReportExplorer />
        <MailedReports layer={layer} />
    </div>;
}
