/** @jsxImportSource @emotion/react */
import Plotly from "plotly.js-basic-dist";
import PropTypes from "prop-types";
import createPlotlyComponent from "react-plotly.js/factory";
import { countCategory } from "../../../../actions/Tenants/config/utils";

const Plot = createPlotlyComponent(Plotly);

export default function CategoryDistribution({ categories, catField, fruits, style }) {

    if (!categories) {
        return null;
    }

    const counted_category = categories.map((item) => ({ ...item, count: countCategory(catField, item, fruits)[0] })).filter((i) => i.count > 0);
    const colors = counted_category.map((i) => i.color);

    const ripening_class_serie: Plotly.Data = {
        type: "pie",
        labels: counted_category.map((i) => i.label),
        values: counted_category.map((i) => i.count),
        marker: {
            colors,
        }
    };
    return <div>
        <Plot
            data={[ripening_class_serie]}
            useResizeHandler={true}
            style={style}
            layout={{
                autosize: true,
                margin: {
                    l: 10,
                    r: 10,
                    t: 20,
                    b: 10,
                    pad: 0
                },
            }}
            config={{
                displayModeBar: false,
                displaylogo: false,
                staticPlot: true,
            }}
        />
    </div>;
}

CategoryDistribution.defaultProps = {
    style: { width: "100%", height: "100%" }
};

CategoryDistribution.propTypes = {
    categories: PropTypes.array,
    fruits: PropTypes.array,
    catField: PropTypes.string,
    style: PropTypes.object
};
