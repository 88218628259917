/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    FormGroup, Input,
    Table
} from "reactstrap";
import { applyDisplayFilterLayerFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig, { useTenantFormOptions } from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";
import { TableCell, TableHeaderCell } from "../../../Helper/Table";
import { RemoveLayerModal } from "./MovePalletsModal";
import StartCheckButton from "./StartCheckButton";
import { isItemAllocatedOnDateHook } from "./hooks";

// Table to show either the pallets or the groups within a PO
export default function RipeningCellItems({ groups, pallets, weekDates, refreshAllocatedLayers }) {

    const config = useConfig();
    const checks = useSelector<any, any>((state) => state.facilityLocations.checks);
    const form_options = useTenantFormOptions();
    const [selectedLayers, setSelectedLayers] = useState({});
    const selectedLayersArray = Object.keys(selectedLayers).filter((key) => selectedLayers[key]);
    const [addPalletModalOpen, setAddPalletModal] = useState(false);
    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();
    // const countALlocatedLayers = countAllocatedLayersHook();

    const fields = applyDisplayFilterLayerFields(
        config?.root_config?.ripening_cell_pallet_fields,
        {
            // fruit_type: "avocado"  # TODO: discuss fruit_type fillters
        }
    );

    const today = dayjs();

    if (!pallets || !fields) {
        return <Loading />;
    }

    const values = (p) => fields.map((i, index) => <TableCell key={index}>{i.set_value({
        config,
        layer: p,
        check: null, // * To be used in check tables and layer overview
        fruit: null, // * Fruit list is not available on overview screen
        children: null, // * children is only available on layer index page
        form_options
    }).display_component()}</TableCell>);


    const header = () => fields.map((i, index) => <TableHeaderCell key={index}>{i.label}</TableHeaderCell>);


    const toggleSelectedLayer = (layer_id) => setSelectedLayers((prev) => ({ ...prev, [layer_id]: !prev[layer_id] }));

    // * Select all pallets (the one that are still in the ripening cell, on this date)
    const selectAll = useCallback(() => {
        setSelectedLayers(pallets.reduce((acc: object, i: any) => ({ ...acc, [i.id]: isItemAllocatedOnDate(i.id, today) }), {}) as object);
    }, [pallets]);
    const deselectAll = () => setSelectedLayers({});

    // Check if all pallets have a selected layer and are allocated for today, ensuring the list is not empty
    const allSelected = pallets.length > 0 && pallets.every((i) => selectedLayers[i.id] && isItemAllocatedOnDate(i.id, today));

    // Handler to toggle select/deselect all
    const toggleSelectAll = () => {
        if (allSelected) {
            deselectAll();
        } else {
            selectAll();
        }
    };


    return <div>
        <div>
            <Button size="sm" className="my-1 me-2 text-nowrap" disabled={selectedLayersArray.length === 0} style={{ backgroundColor: "#2b83b3" }} onClick={() => setAddPalletModal(true)}>Move {selectedLayersArray.length}</Button>
        </div>
        <div >
            <div css={css`min-height: 300px;`}>
                <Table size="sm" responsive={true} borderless={true}>
                    <thead>
                        <tr>
                            <TableHeaderCell><Input checked={allSelected}
                                onChange={toggleSelectAll} type="checkbox" /></TableHeaderCell>
                            {header()}
                            {weekDates.map((date, index) => (
                                <TableHeaderCell key={index} >
                                    {date.format("D MMM YYYY")}
                                </TableHeaderCell>
                            ))}
                            <TableHeaderCell>Check</TableHeaderCell>
                        </tr>
                    </thead>
                    {groups.map((pallets: any, index) => <tbody key={index} css={css`border-width: 1px 0px 1px 0px; border-type: solid; border-color: #323030;`}>
                        {pallets.map((i, indexx) => <tr key={indexx} title={i?.group?.group_query}>
                            <TableCell>
                                <FormGroup check>
                                    <Input disabled={!isItemAllocatedOnDate(i.id, today)} onChange={() => toggleSelectedLayer(i.id)} checked={Boolean(selectedLayers[i.id]) && isItemAllocatedOnDate(i.id, today)} type="checkbox" />
                                </FormGroup>
                            </TableCell>
                            {values(i)}
                            {weekDates.map((date, dateIndex) => (
                                <TableCell key={dateIndex} className={isItemAllocatedOnDate(i.id, date) ? "align-middle text-center bg-info-subtle" : "align-middle text-center bg-body-tertiary text-muted"}>{checks.filter((c) => dayjs(c.created).format("DD-MM-YYYY") === date.format("DD-MM-YYYY") && c.layer_id === i.id)
                                    .map((check, index) => <Link key={index} to={`/layer/${check.layer_id}/add-check/${check.test_id}/summary`}>{check.average_pressure_or_prediction ? `${check.average_pressure_or_prediction} KG` : "-"}</Link>)}</TableCell>
                            ))}
                            <TableCell>
                                {<StartCheckButton
                                    pallet={i}
                                    disabled={!isItemAllocatedOnDate(i.id, today)}
                                    group_pallets={pallets}
                                    group={i.group} />}
                            </TableCell>
                        </tr>)}
                    </tbody>)}


                </Table >
            </div>
        </div>
        <RemoveLayerModal
            setModal={setAddPalletModal}
            modalOpen={addPalletModalOpen}
            selectedLayers={selectedLayersArray}
            refreshAllocatedLayers={refreshAllocatedLayers}
            deselectAll={deselectAll}
        />
    </div>;

}
