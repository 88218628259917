/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button, Container
} from "reactstrap";
import { getFacilityLocation, listFacilityLocations } from "../../../../actions/FacilityLocations/actions";
import { GROUP_STRATEGY } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";
import RipeWiseBreadcrumbs from "../RipeWiseBreadcrumbs";
import { getDateConditionsMap, getRipeningCellPalletGroups, ripeningCellDayNavigator } from "./hooks";
import RipeWiseMetricCardsGroup from "./MetricCards";
import { AddSinglePalletModal, ScanToMoveLayerModal } from "./MovePalletsModal";
import RipeningCellConditionsForm from "./RipeningCellConditionsForm";
import RipeningCellDays from "./RipeningCellDays";
import RipeningCellItems from "./RipeningCellItems";
import { ScanToCheckModal } from "./StartCheckButton";
import { ViewStrategyModal } from "./ViewStrategyModal";

export default function RipeningCellLayout() {
    const params = useParams();
    const dispatch = useDispatch();
    const config = useConfig();


    const currentLocation = useSelector((state: any) => state.facilityLocations.current);

    const location_config = config.get_location({}, { location: "ripening" });

    const dayNavigator = ripeningCellDayNavigator();
    const conditionsMap = getDateConditionsMap(dayNavigator.weekDates);

    const [groupView, setGroupView] = useState(GROUP_STRATEGY.PURCHASE_ORDER);
    const default_group_strategy = location_config?.group_strategy;

    useEffect(() => {
        if (default_group_strategy) {
            setGroupView(default_group_strategy);
        }
    }, [default_group_strategy]);

    const { location_id } = params;

    useEffect(() => {
        dispatch(getFacilityLocation(location_id));
        dispatch(listFacilityLocations({ offset: 0, limit: 100 }));
    }, [location_id]);

    const [addPalletModalOpen, setAddPalletModal] = useState(false);

    // Get table items for the selected group view
    const { groups, pallets } = getRipeningCellPalletGroups(groupView);

    if (!currentLocation) {
        return <Loading />;
    }

    // TODO: add color for ripewise
    return <>

        <div className="px-3 pt-3 pb-5">
            {/* Header */}
            {/* Title */}
            <Container>
                <div className="d-flex align-items-center justify-content-between flex-grow-1 overflow-auto" >
                    <div>
                        <h2 className="mb-1" >
                            {currentLocation?.label}
                        </h2>
                        <RipeWiseBreadcrumbs currentLocation={currentLocation} />
                    </div>
                    <div className="">
                        <Button
                            className="my-1 text-nowrap"
                            css={css`background-color: #2b83b3;`}
                            onClick={() => setAddPalletModal(true)}>Add pallets</Button>
                    </div>
                </div>
            </Container>
            {/* Layers & KPI */}
            <Container>
                <RipeWiseMetricCardsGroup pallets={pallets} lastWeekDay={dayNavigator.lastWeekDay} />
            </Container>
            <RipeningCellDays dayNavigator={dayNavigator} conditionsMap={conditionsMap} />
        </div>
        {/* Ripening Days */}
        <Container >
            <div className="bg-body rounded shadow">
                <div className="p-lg-5 p-2" >
                    <div className="d-flex justify-content-between">
                        <ViewStrategyModal groupView={groupView} setGroupView={setGroupView} />
                        <div>
                            <ScanToMoveLayerModal pallets={pallets} refreshAllocatedLayers={dayNavigator.refreshAllocatedLayers} />
                            <ScanToCheckModal pallets={pallets} groups={groups} />
                        </div>
                    </div>
                    <RipeningCellItems
                        groups={groups}
                        pallets={pallets}
                        weekDates={dayNavigator.weekDates}
                        refreshAllocatedLayers={dayNavigator.refreshAllocatedLayers}
                    />
                </div>
            </div>
        </Container>
        {/* Ripening Cell Conditions Form */}
        <RipeningCellConditionsForm refreshConditions={dayNavigator.refreshConditions} editDate={dayNavigator.editDate} closeCellConditionsForm={dayNavigator.closeCellConditionsForm} />
        <AddSinglePalletModal setModal={setAddPalletModal} modalOpen={addPalletModalOpen} refreshAllocatedLayers={dayNavigator.refreshAllocatedLayers} />
    </>;

}

