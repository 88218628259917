/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { listAllocatedLayers, listLocationChecks, listLocationConditions } from "../../../../actions/FacilityLocations/actions";
import { GROUP_STRATEGY, GROUP_STRATEGY_LABELS, LAYER_TYPE } from "../../../../actions/Layers/constants";

dayjs.extend(customParseFormat);


// Get the dates for the week
const getWeekDates = (lastWeekDay) => {
    return Array.from({ length: 5 }, (_, i) => lastWeekDay.subtract(4 - i, "day"));
};

const DATE_URL_FORMAT = "DD-MM-YYYY";
const DATE_QUERY_FORMAT = "YYYY-MM-DD";
const DATE_DISPLAY_FORMAT = "DD MMM YYYY";

// RipeningCellDays component for navigating through ripening days
export function ripeningCellDayNavigator() {

    const today = dayjs();
    const dispatch = useDispatch();
    const params = useParams();
    const [lastWeekDay, setLastWeekDay] = useState(params.to_date ? dayjs(params.to_date, DATE_URL_FORMAT) : today);
    const [weekDates, setWeekDates] = useState(getWeekDates(today));
    const navigate = useNavigate();
    const { location_id } = params;

    const editDate = dayjs(params.edit_date, DATE_URL_FORMAT);

    // Nicely formatted date for in the URL
    const UrlFromDate = weekDates[0].format(DATE_URL_FORMAT);
    const UrlToDate = lastWeekDay.format(DATE_URL_FORMAT);

    // Load date for the week + 1 day before and after for better scrolling experience
    const queryFromDate = weekDates[0].subtract(1, "day").format(DATE_QUERY_FORMAT);
    const queryToDate = lastWeekDay.add(1, "day").format(DATE_QUERY_FORMAT);


    const refreshAllocatedLayers = useCallback(
        () => (location_id ? dispatch(listAllocatedLayers(location_id, queryFromDate, queryToDate) as any) : Promise.resolve()),
        [location_id, queryFromDate, queryToDate]
    );
    const refreshLocationChecks = useCallback(
        () => (location_id ? dispatch(listLocationChecks(location_id, queryFromDate, queryToDate) as any) : Promise.resolve()),
        [location_id, queryFromDate, queryToDate]
    );

    const refreshConditions = useCallback(() => {
        // * Fetch conditions also for the previous week to enable the carryover function.
        dispatch(listLocationConditions(location_id, weekDates[0].subtract(5, "day").format(DATE_QUERY_FORMAT), queryToDate) as any);
    }, [location_id, weekDates[0], queryToDate]);


    const openCellConditionsForm = useCallback((date) => navigate(`/ripening-cell/${location_id}/from/${UrlFromDate}/to/${UrlToDate}/edit/${date.format(DATE_URL_FORMAT)}`), [location_id, UrlFromDate, UrlToDate]);
    const closeCellConditionsForm = useCallback(() => navigate(`/ripening-cell/${location_id}/from/${UrlFromDate}/to/${UrlToDate}`), [location_id, UrlFromDate, UrlToDate]);

    useEffect(() => {
        if (params.edit_date) {
            navigate(`/ripening-cell/${location_id}/from/${UrlFromDate}/to/${UrlToDate}/edit/${params.edit_date}`);
        } else {
            navigate(`/ripening-cell/${location_id}/from/${UrlFromDate}/to/${UrlToDate}`);
        }
    }, [queryFromDate, queryToDate, params.edit_date]);

    useEffect(() => {
        const weekDates = getWeekDates(lastWeekDay);
        setWeekDates(weekDates);
        const queryFromDate = weekDates[0].subtract(1, "day").format(DATE_QUERY_FORMAT);
        const queryToDate = lastWeekDay.add(1, "day").format(DATE_QUERY_FORMAT);

        if (!location_id) return;
        dispatch(listAllocatedLayers(location_id, queryFromDate, queryToDate) as any);
        dispatch(listLocationChecks(location_id, queryFromDate, queryToDate) as any);
        dispatch(listLocationConditions(location_id, weekDates[0].subtract(6, "day").format(DATE_QUERY_FORMAT), queryToDate) as any);

    }, [location_id, lastWeekDay]);


    return { refreshConditions, refreshAllocatedLayers, refreshLocationChecks, lastWeekDay, setLastWeekDay, weekDates, editDate, today, openCellConditionsForm, closeCellConditionsForm };

}


export function getDayConditions(date) {
    const conditions = useSelector((state: any) => state.facilityLocations.conditions);

    const filteredConditions = conditions.filter(
        (cond) => formatDate(dayjs(cond.active_since)) === formatDate(date)
    ).sort((a, b) => dayjs(b.created).diff(dayjs(a.created)));

    // TODO: make list condifurable
    const keyLabelMap = [
        { key: "ripening_program", label: "Ripening Program" },
        { key: "ripening_target", label: "Ripening Target" },
        { key: "temperature", label: "Temperature" },
        { key: "ethylene", label: "Ethylene" }
    ].map((item) => {
        const condition = filteredConditions.find((cond) => cond.key === item.key);
        return { ...item, value: condition ? condition.value : undefined };
    });
    return { filteredConditions, keyLabelMap };

}


type KeyLabelMapItem = {
    key: string;
    label: string;
    carryover: boolean;
    value?: any;
    class: string;
};


// This function will return a map of conditions for each day in the week
// It will also carryover settings from previous days if no settings are set for the current day
export function getDateConditionsMap(weekDates: dayjs.Dayjs[]) {
    const conditions = useSelector((state: any) => state.facilityLocations.conditions);

    // Sort conditions once by their created date and convert `active_since` to `dayjs` object
    const sortedConditions = conditions
        .sort((a, b) => dayjs(b.created).diff(dayjs(a.created)))
        .map((cond) => ({ ...cond, active_since: dayjs(cond.active_since) }));

    // Get unique dates from conditions and week dates
    const uniqueDates = [...sortedConditions.map((i) => i.active_since), ...weekDates]
        .sort((a, b) => a.diff(b))
        .reduce((acc, date) => {
            const formattedDate = formatDate(date);
            acc[formattedDate] = true;
            return acc;
        }, {} as Record<string, boolean>);

    let lastFilledKeyLabelMap: KeyLabelMapItem[] | null = null;

    // Build the dateConditionsMap set for each day
    const dateConditionsMap = Object.keys(uniqueDates).map((currentDateStr) => {
        // Filter conditions for the current date
        const filteredConditions = sortedConditions.filter(
            (cond) => formatDate(cond.active_since) === currentDateStr
        );

        const keyLabelMap: KeyLabelMapItem[] = [
            { key: "ripening_program", label: "Ripening Program", carryover: true, class: "" },
            { key: "ripening_target", label: "Ripening Target", carryover: true, class: "" },
            { key: "temperature", label: "Temperature", carryover: true, class: "" },
            { key: "ethylene", label: "Ethylene", carryover: false, class: "" }
        ].map((item) => {
            const condition = filteredConditions.find((cond) => cond.key === item.key);
            return { ...item, value: condition ? condition.value : undefined };
        });

        // Apply carryover logic
        const carriedOverKeyLabelMap = keyLabelMap.map((item) => {
            if (item.value) {
                return { ...item, value: item.value, class: "fw-bold" };
            }
            // Carryover from previous day(s) if applicable
            if (item.carryover && lastFilledKeyLabelMap) {
                const prevItem = lastFilledKeyLabelMap.find((prev) => prev.key === item.key);
                if (prevItem?.value) {
                    return { ...item, value: prevItem.value, class: "fw-light" };
                }
            }

            return item;
        });

        // Update lastFilledKeyLabelMap if the current day has values
        if (carriedOverKeyLabelMap.some((x) => x.value)) {
            lastFilledKeyLabelMap = carriedOverKeyLabelMap;
        }

        return { date: currentDateStr, keyLabelMap: carriedOverKeyLabelMap };
    });

    // Convert the array to a map object with date strings as keys
    return dateConditionsMap.reduce((acc, { date, keyLabelMap }) => {
        acc[date] = { keyLabelMap, hasValues: keyLabelMap.some((x) => x.value) };
        return acc;
    }, {} as Record<string, { keyLabelMap: KeyLabelMapItem[], hasValues: boolean }>);
}

// Utility to format date as 'DD MMM YYYY'
export const formatDate = (date) => date.format(DATE_DISPLAY_FORMAT);


// * Return a list of pallets, the pallets contain settings for setting up the group
export function getRipeningCellPalletGroups(groupView) {
    const layers = useSelector<any, any>((state) => state.facilityLocations.layers);
    const pallets = getRipeningCellPallets(groupView, layers);
    const groupsObject = pallets.reduce((acc, pallet) => {
        const key = pallet.group ? pallet.group.group_query : "pallet";
        if (!acc[key]) {
            return { ...acc, [key]: [pallet] };
        }
        return { ...acc, [key]: [...acc[key], pallet] };
    }, {});
    const groups = Object.values(groupsObject);

    return { groups, pallets };
}

export function isItemAllocatedOnDateHook() {
    const allocatedLayersPerDay = useSelector<any, any>((state) => state.facilityLocations.allocatedLayersPerDay);
    return useCallback((layer_id, date) => {

        const allocatedLayers = allocatedLayersPerDay.find((day) => dayjs(day.date).format(DATE_URL_FORMAT) === date.format(DATE_URL_FORMAT));
        if (!allocatedLayers) {
            return false;
        }
        return allocatedLayers.layer_ids.includes(layer_id);

    }, [allocatedLayersPerDay]);
}

export function countAllocatedLayersHook() {
    const allocatedLayersPerDay = useSelector<any, any>((state) => state.facilityLocations.allocatedLayersPerDay);
    return useCallback((date) => {
        const allocatedLayers = allocatedLayersPerDay.find((day) => dayjs(day.date).format(DATE_URL_FORMAT) === date.format(DATE_URL_FORMAT));
        return allocatedLayers ? allocatedLayers.layer_ids.length : 0;
    }, [allocatedLayersPerDay]);
}


const getRipeningCellPallets = (group_strategy: string, layers: any) => {
    const pallets = layers.filter((layer) => layer.type === LAYER_TYPE.PALLET);

    const getParent = (p) => p.parents?.[0] || { id: "orphan", label: "Orphan" }; // * If the pallet has no parent, it is an orphan)

    // iF tenants only allows checks per pallet, there is no group possebiltiy
    if (group_strategy === GROUP_STRATEGY.PALLET) {
        return pallets.map((p) => ({
            ...p,
            group: false,
            parent_id: getParent(p).id
        }));
    }

    // iF tenants only allows checks per PO or Pallet, the PO is the group
    if (group_strategy === GROUP_STRATEGY.PURCHASE_ORDER) {
        return pallets.map((p) => ({
            ...p,
            parent_id: getParent(p).id,
            group: {
                group_label: `${GROUP_STRATEGY_LABELS[GROUP_STRATEGY.PURCHASE_ORDER]} ${getParent(p).label}`,
                group_query: `${group_strategy}=${getParent(p).id}`,
                group_strategy
            } }));

    }

    return pallets.map((p) => ({
        ...p,
        parent_id: getParent(p).id,
        // group: p[group_strategy] ? {
        //     group_label: `${GROUP_STRATEGY_LABELS[group_strategy]} ${p[group_strategy]}`,
        //     group_query: `${getParent(p).id} ${group_strategy}=${p[group_strategy]}`,
        //     group_strategy: group_strategy
        // } : false
        group: {
            group_label: `${GROUP_STRATEGY_LABELS[group_strategy]} ${p[group_strategy] || "N/A"}`,
            group_query: `${getParent(p).id} ${group_strategy}=${p[group_strategy] || "N/A"}`,
            group_strategy
        }
    }));
};

// * Technically it would be better to return a list of PO with all the children...but this will not show the pallets from which the parent is not also allocated to this cell (orphan pallets)
//  Return a list of PO containing a list of children (pallets), the pallets contain settings for setting up the group
// const getRipeningCellPurchaseOrders = (group_strategy: string, layers: any) => {
//     const pallets = layers.filter((layer) => layer.type === LAYER_TYPE.PALLET);
//     const purchaseOrders = layers.filter((layer) => layer.type === LAYER_TYPE.PURCHASE_ORDER).map((po) => ({
//         ...po,
//         children: po.children.map((pallet) => ({ ...pallet, ...(pallets.find((p) => p.id === pallet.id)) })),
//         // children_ids: po.children.map((pallet) => pallet.id)
//     }));

//     // iF tenants only allows checks per pallet, there is no group possebiltiy
//     if (default_group_strategy === GROUP_STRATEGY.PALLET) {
//         return purchaseOrders.map((po) => ({
//             ...po,
//             children: po.children.map((pallet) => ({ ...pallet, parent_id: po.id, group: false }))
//         }));
//     }

//     // iF tenants only allows checks per PO or Pallet, the PO is the group
//     if (default_group_strategy === GROUP_STRATEGY.PURCHASE_ORDER) {
//         return purchaseOrders.map((po) => ({
//             ...po,
//             children: po.children.map((pallet) => ({
//                 ...pallet,
//                 parent_id: po.id,
//                 group: {
//                     group_label: `${GROUP_STRATEGY_LABELS[GROUP_STRATEGY.PURCHASE_ORDER]} ${po.label}`,
//                     group_query: `${default_group_strategy}=${po.id}`,
//                     group_strategy: default_group_strategy
//                 }
//             }))
//         }));
//     }

//     return purchaseOrders.map((po) => {
//         return {
//             ...po,
//             children: po.children.map((pallet) => ({
//                 ...pallet,
//                 parent_id: po.id,
//                 group: pallet[default_group_strategy] ? {
//                     group_label: `${GROUP_STRATEGY_LABELS[default_group_strategy]} ${pallet[default_group_strategy]}`,
//                     group_query: `${po.id} ${default_group_strategy}=${pallet[default_group_strategy]}`,
//                     group_strategy: default_group_strategy
//                 } : false
//             }))
//         };

//     });


// };
