/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import {
    Button
} from "reactstrap";
import {
    patchLayer
} from "../../../../../actions/Layers/actions";

import useAuthorization from "../../../../../utils/authorization";

export const QC_STATUS = {
    UNREADY: "",
    DRAFT: "DRAFT",
    SENT: "SENT",
};

export default function QCStatusButton({ field, pdf_config }) {
    const dispatch = useDispatch();
    const layer = useSelector<any>((state) => state.layers.current) as any;
    const auth = useAuthorization();


    if (!pdf_config) {
        return null;
    }

    if (!auth.userBelongsToOneOfTeams(pdf_config.teams_that_can_manage_qc_status)) {
        // * feature disabled
        return null;
    }

    if (layer[field] === QC_STATUS.SENT) {
        // * Nothing to do here
        return <Button color="primary" size="sm" className="me-2" disabled={true} onClick={() => null }>Report sent</Button>;
    }

    if (layer[field] === QC_STATUS.DRAFT) {
        return <Button color="warning" size="sm" className="me-2" onClick={() => dispatch(patchLayer(layer.id, field, QC_STATUS.UNREADY, true) as any) }>Edit</Button>;
    }
    return <Button color="success" size="sm" className="me-2" onClick={() => dispatch(patchLayer(layer.id, field, QC_STATUS.DRAFT, true) as any)}>Submit for review</Button>;

}
