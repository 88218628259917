/** @jsxImportSource @emotion/react */
import { faExchange, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import Papa from "papaparse";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Col, Container,
    FormGroup, FormText,
    Input,
    Label,
    Modal, ModalBody, ModalFooter, ModalHeader, Row, Table
} from "reactstrap";
import { LAYER_STAGE } from "../../../actions/Layers/constants";
import { getTenant } from "../../../actions/Tenants/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import {
    createTenantFacilityLocation,
    deleteAllTenantFacilityLocations,
    deleteTenantFacilityLocation,
    importTenantFacilityLocation,
    listTenantFacilityLocations,
    updateTenantFacilityLocation
} from "../../../actions/Tenants/tenantFacilityLocationActions";
import { isFilledArray } from "../../../utils";
import MetaForm from "../../Forms/MetaForm";
import { Loading } from "../../Helper/Loading";
import TenantPageWrappedInConfigProvider from "./TenantPageWrappedInConfigProvider";

const defaultValues = {
    label: "",
    type: null,
    stage: null,


};

const defaultCsvOption = {
    label: "",
    type: null,
    stage: null,
};

// * Wrap this page in the config of the tenant we are editing
export default function WrappedInConfigProviderTenantEditForm() {
    return <TenantPageWrappedInConfigProvider>
        <TenantEditFacilityLocationsForm/>
    </TenantPageWrappedInConfigProvider>;
}

const RIPENING_CELL = "ripening_cell";
const STORAGE_CELL = "storage_cell";
const DOCK = "dock";

// TODO: define non ripening cell types
export const LOCATION_TYPES = {
    RIPENING_CELL,
    STORAGE_CELL,
    DOCK,
};

export const LOCATION_TYPE_LABELS = {
    [RIPENING_CELL]: "Ripening cell",
    [STORAGE_CELL]: "Storage cell",
    [DOCK]: "Dock",
};

function TenantEditFacilityLocationsForm() {
    const current = useSelector((state) => state.tenants.current);
    const options = useSelector((state) => state.tenants.locations);
    const isLoading = useSelector((state) => state.tenants.isLoading);
    const [isOpen, setModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const [query, setQuery] = useState(defaultValues);
    const [showAllToBeImportedItems, setShowAllToBeImportedItems] = useState(false);
    const navigate = useNavigate();
    const config = useConfig();
    const [filter, setFilter] = useState({ stage: [], type: [] });

    const locations = options.map((i) => {
        // TODO: you cannot import/export meta for now
        const { location_id, type, stage, label } = i;
        return { location_id, type, stage, label };
    });


    const { tenant_name } = current;
    const { tenant_id } = params;

    const [importData, setImportData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(false);

    const filteredLocations = useMemo(() => {
        if (locations) {
            return locations.filter((i) => !isFilledArray(filter.stage) || filter.stage.includes(i.stage)).filter((i) => !isFilledArray(filter.type) || filter.type.includes(i.type));
        }
        return [];
    }, [filter.stage, filter.type, locations]);

    // * refresh the tenant object if needed
    useEffect(() => {
        if (tenant_id) {
            dispatch(getTenant(tenant_id));
            dispatch(listTenantFacilityLocations(tenant_id));
        }
    }, [tenant_id]);


    const fileChangeHandler = (e) => {
        const file = e.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete(results) {
                    const options = results.data.map((i) => ({ ...defaultCsvOption,
                        location_id: i.location_id ? i.location_id.trim() : null,
                        type: i.type ? i.type.trim().toLowerCase() : null,
                        stage: i.stage ? i.stage.trim().toLowerCase() : null,
                        label: i.label ? i.label.trim() : null,
                    }));

                    setImportData(options);
                    setSelectedFile(file);
                },
            });
        }
    };

    const handleImport = () => dispatch(importTenantFacilityLocation(params.tenant_id, filteredImportData));


    const form = [
        {
            label: "Label",
            name: "label",
            type: "text",
        },
        {
            label: "Type",
            name: "type",
            type: "single-select",
            options: Object.values(LOCATION_TYPES).map((i) => ({ label: i, value: i })),
        },
        {
            label: "Stage",
            name: "stage",
            type: "single-select",
            options: Object.values(LAYER_STAGE).map((i) => ({ value: i, label: i })),
        },
    ];

    const deleteOption = (option) => dispatch(deleteTenantFacilityLocation(tenant_id, option.location_id));

    const deleteAll = () => dispatch(deleteAllTenantFacilityLocations(tenant_id));

    const filterForm = [
        {
            label: "Stage",
            name: "stage",
            type: "single-select",
            options: Object.values(LAYER_STAGE).map((i) => ({ value: i, label: i })),
            return_array: true,
            multi: true,
            lg: 4,
        },
        {
            label: "Type",
            name: "type",
            type: "single-select",
            options: Object.values(LOCATION_TYPES).map((i) => ({ label: i, value: i })),
            return_array: true,
            multi: true,
            lg: 4,
        }
    ];
    const cancel = () => {
        setModal(false);
        setQuery(defaultValues);
    };

    const newItem = () => {
        setModal(true);
        setQuery(defaultValues);
    };
    const onSubmit = () => {
        if (query.location_id) {
            dispatch(updateTenantFacilityLocation(tenant_id, query));
        } else {
            dispatch(createTenantFacilityLocation(tenant_id, query));
        }
        setModal(true);
        setQuery(defaultValues);
    };


    const exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const exportOptions = () => {
        const csvData = Papa.unparse(locations);
        exportData(csvData, `export-${tenant_name}-${dayjs().format("DD-MMM-YYYY-HHmm")}-facility-locations.csv`, "text/csv;charset=utf-8;");
    };

    const filteredImportData = importData.filter((i) => i.label && i.type && i.stage);


    if (!locations && isLoading) return <Loading></Loading>;
    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col lg="12">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2 className="">Facility Locations</h2>
                            <p className="lead">Edit tenant <b>{tenant_name}</b> facility locations.</p>
                        </div>
                        <div>
                            <Button className="text-nowrap me-3" color="primary" onClick={() => newItem()}><FontAwesomeIcon icon={faPlus} /> Add option</Button>
                            <Button className="text-nowrap me-3" color="primary" onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faExchange} /> Import</Button>
                            <Button className="btn-close my-1" size="lg" onClick={() => navigate(`/tenants/${tenant_id}`)} ></Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <div>
                <MetaForm
                    meta={filterForm}
                    grid={true}
                    object={filter}
                    setValue={(field, value) => setFilter((oldFilter) => ({ ...oldFilter, [field]: value }))} />
            </div>
            <Row className="justify-content-center py-5">
                <Col lg="12">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>location_id</th>
                                <th>Type</th>
                                <th>Stage</th>
                                <th>Label</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(filteredLocations || []).map((i, index) => (
                                <tr key={index} >
                                    <td className="align-middle" title={i.location_id}>{i.location_id.slice(0, 7)}</td>
                                    <td className="align-middle ">{i.type}</td>
                                    <td className="align-middle">{i.stage}</td>
                                    <td className="align-middle">{i.label}</td>
                                    <td className="text-end">
                                        <Button color="primary" className="me-1 mb-1" outline onClick={() => {
                                            setQuery(i);
                                            setModal(true);
                                        }}>Edit</Button>
                                        <Button color="danger" className="me-1 mb-1" outline onClick={() => deleteOption(i)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Modal size="md" isOpen={isOpen} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()} >{query.location_id ? "Edit" : "New"} Location</ModalHeader>
                <ModalBody>
                    <MetaForm meta={form} setValue={(field, value) => setQuery((query) => ({ ...query, [field]: value }))} object={query} config={config} />
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => cancel()}>Cancel</Button>
                    <Button color="primary" disabled={!query.label || !query.stage || !query.type || isLoading} className="me-1 mb-1" onClick={() => onSubmit()}>Submit</Button>
                </ModalFooter>
            </Modal >

            <Modal size="xl" isOpen={importModal} toggle={() => setImportModal(false)} >
                <ModalHeader toggle={() => setImportModal(false)} >Import / Delete all</ModalHeader>
                <ModalBody>

                    <FormGroup>
                        <Label for="exampleFile">CSV</Label>
                        <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            onChange={fileChangeHandler}
                        />

                        {selectedFile ? (
                            <div className="py-3">
                                <FormText >
                                    <div>Filename: {selectedFile.name}</div>
                                    <div>Filetype: {selectedFile.type}</div>
                                    <div>Size in bytes: {selectedFile.size}</div>
                                    <div>Items: {importData && importData.length}</div>
                                    <div>Valid items: {importData && filteredImportData.length}</div>
                                </FormText>
                            </div>

                        ) : (<FormText>Upload csv file without headers here.</FormText>)}
                    </FormGroup>

                    {importData.length > 0 && <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>location_id</th>
                                <th>Type</th>
                                <th>Stage</th>
                                <th>Label</th>
                            </tr>
                        </thead>
                        <tbody>
                            {importData.slice(0, showAllToBeImportedItems ? importData.length : 10).map((i, index) => (
                                <tr key={index} >
                                    <td className={`align-middle`}>{i.location_id}</td>
                                    <td className={`align-middle ${i.type || "bg-danger"}`}>{i.type}</td>
                                    <td className={`align-middle ${i.stage || "bg-danger"}`}>{i.stage}</td>
                                    <td className={`align-middle ${i.label || "bg-danger"}`}>{i.label}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>}
                    {importData.length > 10 && !showAllToBeImportedItems
                        && <div className="text-center py-3">
                            <span onClick={() => setShowAllToBeImportedItems(true)} className="btn-link">Show all</span>
                        </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => setImportModal(false)}>Cancel</Button>
                    <Button color="light" className="me-1 mb-1" onClick={() => exportOptions()}>Export</Button>
                    <Button color="primary" disabled={isLoading || !importData || filteredImportData.length === 0} className="me-1 mb-1" onClick={() => handleImport()}>Import</Button>
                    <Button color="danger" disabled={isLoading} className="me-1 mb-1" onClick={() => deleteAll()}>Delete All</Button>
                </ModalFooter>
            </Modal >

        </Container>
    );
}
