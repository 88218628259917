/* eslint-disable no-case-declarations */
import { toast } from "../../utils/toast";
import { formatCheck2Frontend } from "../Checks/util";
import {
    CHECKS, CHILDREN,
    CREATE, DELETE, DELETE_LOCATION_HISTORY, DELETE_REPORT, GET,
    GET_LOCATION_HISTORY, LIST,
    PARENTS, PATCH,
    POST_LOCATION_HISTORY,
    SUB_CREATE, UPDATE,
    UPLOAD_REPORT
} from "./actionsTypes";
import { LayerFilter, defaultFilter, defaultLayer } from "./constants";
import { format2Frontend } from "./util";


export interface LayersState {
    items: any;
    isLoading: boolean;
    childrenLoading: boolean;
    isUpdating: boolean;
    current: any;
    locationHistory: any[];
    locationHistoryLoading: boolean;
    checks: any[];
    children: any;
    parents: any;
    checksLoading: boolean;
    filter: LayerFilter;
    per_stage: any[];
    sub_layer?: any;
}

const layers = {
    items: false,
    isLoading: false,
    childrenLoading: false,
    isUpdating: false,
    current: defaultLayer,
    locationHistory: [],
    locationHistoryLoading: false,
    checks: [],
    children: false,
    parents: false,
    checksLoading: false,
    filter: defaultFilter,
    per_stage: [],
};
export default (state: LayersState = layers, action):LayersState => {
    switch (action.type) {
    case LIST.FILTER: {
        const { filter, extendFilter } = action.payload;
        if (extendFilter) {
            return { ...state, filter: { ...state.filter, ...filter } };
        }
        return { ...state, filter };
    }

    case LIST.LOADING:
        return { ...state, isLoading: true, filter: action.payload.request.filter_object };
    case GET.LOADING:
        return { ...state, isLoading: true };

    case SUB_CREATE.LOADING:
    case CREATE.LOADING:
        toast.info("Saving...", {
            toastId: "create-layer",
            autoClose: 1500,
        });
        return { ...state, isUpdating: true };


    case UPDATE.LOADING:
        toast.info("Updating...", {
            toastId: `update-layer-${action.payload.request.data.layer_id}`,
            autoClose: 1500,
        });
        return { ...state, isUpdating: true };

    case PATCH.STATE:
        return updateLayerFieldInState(state, action.payload.layer_id, action.payload.field, action.payload.value);

    case PATCH.LOADING:
        return {
            ...updateLayerFieldInState(state, action.payload.request.layer_id, action.payload.request.data.field, action.payload.request.data.state_value),
            isUpdating: true
        };
    case PATCH.SUCCESS:
        return { ...state, isUpdating: false };
    case PATCH.ERROR:
        return { ...state, isUpdating: false };

    case DELETE.LOADING:
        toast.warning("Deleting...", {
            toastId: "delete-layers",
            autoClose: 1500,
        });
        return {
            ...state,
            isUpdating: true,
            items: {
                ...state.items,
                results: state.items.results.filter((x) => x.layer_id !== action.payload.request.layer_id),
            },
        };
    case LIST.ERROR:
    case GET.ERROR:
    case SUB_CREATE.ERROR:
        return { ...state, isLoading: false };
    case CREATE.ERROR:
    case UPDATE.ERROR:
    case DELETE.ERROR:
        return { ...state, isLoading: false, isUpdating: false };
    case CHILDREN.LOADING:
        return { ...state, childrenLoading: true };

    case POST_LOCATION_HISTORY.LOADING:
        return {
            ...updateLayerLatestLocationFieldInState(state, action.payload.request.data.layer_id, action.payload.request.data),
            locationHistoryLoading: true
        };
    case DELETE_LOCATION_HISTORY.LOADING:
    case GET_LOCATION_HISTORY.LOADING:
        return { ...state, locationHistoryLoading: true };
    case PARENTS.LOADING:
        return { ...state, isLoading: true };
    case CHILDREN.ERROR:
        return { ...state, childrenLoading: false };
    case PARENTS.ERROR:
        return { ...state, isLoading: false };
    case GET_LOCATION_HISTORY.ERROR:
        return { ...state, locationHistoryLoading: false };
    case CHECKS.LOADING:
        return { ...state, checksLoading: true };
    case CHECKS.ERROR:
        return { ...state, checksLoading: false };

    case LIST.SUCCESS:
        return { ...state, isLoading: false, items: { ...action.payload.data, results: action.payload.data.results.map(format2Frontend) } };

    case CHECKS.SUCCESS:
        return { ...state, checksLoading: false, checks: action.payload.data.map(formatCheck2Frontend) };

    case GET_LOCATION_HISTORY.SUCCESS:
    case DELETE_LOCATION_HISTORY.SUCCESS:
        return { ...state, locationHistoryLoading: false, locationHistory: action.payload.data };

    case CHILDREN.SUCCESS:
        const children = { ...action.payload.data, results: action.payload.data.results.map(format2Frontend) };
        return { ...state, childrenLoading: false, children, current: { ...state.current, children: children.results } };

    case PARENTS.SUCCESS:
        const parents = { ...action.payload.data, results: action.payload.data.results.map(format2Frontend) };
        return { ...state, isLoading: false, parents, current: { ...state.current, parents: parents.results } };

    case GET.SUCCESS:
        return { ...state, isLoading: false, current: format2Frontend(action.payload.data) };


    case SUB_CREATE.SUCCESS:
        toast.update("create-layer", { render: "Item created!", type: "success", autoClose: 3500 });
        return {
            ...state, isLoading: false, isUpdating: false, sub_layer: format2Frontend(action.payload.data),
        };

    case CREATE.SUCCESS:
        toast.update("create-layer", { render: "Item created!", type: "success", autoClose: 3500 });
        return {
            ...state, isLoading: false, isUpdating: false, current: format2Frontend(action.payload.data),
        };

    case UPDATE.SUCCESS:
        toast.update(`update-layer-${action.payload.data.layer_id}`, { render: "Item updated!", type: "success", autoClose: 3500 });
        return { ...state, isLoading: false, isUpdating: false, current: format2Frontend(action.payload.data) };

    case DELETE.SUCCESS:
        toast.update("delete-layers", { render: "Item deleted", type: "warning", autoClose: 3500 });
        return {
            ...state, isLoading: false, isUpdating: false, current: defaultLayer,
        };

    case LIST.RESET:
        return { ...state, items: { count: 0, results: [] } };

    case UPLOAD_REPORT.SUCCESS:
        toast.success("Report uploaded successfully");
        const { blob_url, meta_key } = action.payload.data;
        return {
            ...state,
            current: {
                ...state.current,
                [meta_key]: blob_url,
            }
        };
    case DELETE_REPORT.SUCCESS:
        toast.success("Report deleted successfully");
        const keyToRemove = action.payload.data;
        const current = { ...state.current };
        delete current[keyToRemove];
        return { ...state, current };

    default:
        return state;
    }
};


const updateLayerFieldInState = (state, layerId, field, value) => {
    if (["location_id", "stage"].includes(field)) {
        return updateLayerLatestLocationFieldInState(state, layerId, field, value);
    }
    const updatedItems = state.items ? {
        ...state.items,
        results: state.items.results.map((i) => {
            if (i.id === layerId) {
                const updatedItem = {
                    ...i,
                    [field]: value,
                };
                // Update layer.latest_location[field] if the field is 'stage' or 'location_id'
                if (field === "stage" || field === "location_id") {
                    updatedItem.latest_location = {
                        ...updatedItem.latest_location,
                        [field]: value,
                    };
                }
                return updatedItem;
            }
            return i;
        }),
    } : state.items;

    return {
        ...state,
        current: {
            ...state.current,
            [field]: value,
        },
        items: updatedItems,
    };
};

const updateLayerLatestLocationFieldInState = (state, layerId, field_or_object, value = null) => {
    const new_object = typeof field_or_object === "object" ? field_or_object : { [field_or_object]: value };
    return {
        ...state,
        current: {
            ...state.current,
            latest_location: {
                ...(state.current.latest_location && typeof state.current.latest_location === "object" ? state.current.latest_location : {}),
                ...new_object
            },
        },
        items: state.items ? {
            ...state.items,
            results: state.items.results.map((i) => {
                if (i.id === layerId) {
                    return {
                        ...i,
                        latest_location: {
                            ...i.latest_location,
                            ...new_object
                        },
                    };
                }
                return i;
            }),
        } : state.items,
    };
};
