import { attributeIsSet } from "../../../components/scenes/Tenants/Forms/FlagConfigForm";
import { MinMaxAvg } from "./constantsTyped";

export const countCategory = (field, label, avocados) => {
    if (!avocados || avocados.length === 0) {
        return [0, "-"];
    }
    const filtered = avocados.filter((avo) => {
        if (!avo[field]) return false;
        if (attributeIsSet(label.gte) && Number(avo[field]) < label.gte) {
            return false;
        }
        if (attributeIsSet(label.lt) && Number(avo[field]) >= label.lt) {
            return false;
        }
        if (attributeIsSet(label.gt) && Number(avo[field]) <= label.gt) {
            return false;
        }
        if (attributeIsSet(label.lte) && Number(avo[field]) > label.lte) {
            return false;
        }
        return true;
    });
    const result = [filtered.length, `${parseFloat(((filtered.length / avocados.length) * 100).toFixed(2))}%`];
    return result;
};


export const find_category = (value, categories) => {
    const cat = categories.find((i) => {
        if (attributeIsSet(i.gte) && Number(value) < i.gte) {
            return false;
        }
        if (attributeIsSet(i.lt) && Number(value) >= i.lt) {
            return false;
        }
        if (attributeIsSet(i.gt) && Number(value) <= i.gt) {
            return false;
        }
        if (attributeIsSet(i.lte) && Number(value) > i.lte) {
            return false;
        }
        if (attributeIsSet(i.eq) && Number(value) !== i.eq) {
            return false;
        }
        return true;
    });
    if (typeof cat === "undefined") {
        return false;
    }
    return cat;
};

// * wrapper for value to category
export const kg_to_ripening_stage = (kg, categories) => value_to_category(kg, categories);

export const value_to_category = (val, categories) => {
    const NoStage = {
        label: "-",
        color: "muted",
    };

    if (val === null || val === undefined || Number.isNaN(val) || val === "") {
        return NoStage;
    }

    const cat = find_category(val, categories);

    if (cat === false) {
        return NoStage;
    }

    return cat;
};

const isDefined = (i) => i !== null && typeof i !== "undefined";

export const calculateMinMaxAvg = (values, unity): MinMaxAvg => {
    values = values.filter(isDefined);
    let avg_raw: number | null = null;
    let avg: string | number = "-";
    let min:string | number = "-";
    let max: string | number = "-";
    if (values.length > 0) {
        const sumWithInitialValue = values.reduce((previousValue, nextValue) => parseFloat(previousValue) + parseFloat(nextValue), 0);
        avg_raw = sumWithInitialValue / values.length;
        avg = `${(avg_raw).toFixed(2)} ${unity}`;
        min = Math.min(...values);
        max = Math.max(...values);
        min = isDefined(min) ? `${(min).toFixed(2)} ${unity}` : "-";
        max = isDefined(max) ? `${(max).toFixed(2)} ${unity}` : "-";
    }
    return {
        min, max, avg, avg_raw
    };
};

export const createBarcodeFilter = (regexArray: RegExp[]) => (barcode) => {
    if (!barcode) return null;
    // Check if the regexArray is empty, if so return the barcode
    if (regexArray.length === 0) return barcode;
    // Attempt to match the barcode against the regexes
    const matches = regexArray.map((regex) => barcode.match(regex));
    // Return the matches if exist, otherwise return null
    // eslint-disable-next-line no-restricted-syntax
    for (const match of matches) {
        const result = match?.[1];
        if (result) {
            return result;
        }
    }
    return null;
};

export const calculateAbsoluteDifference = (value1: number, value2: number) => {
    return Math.abs(value1 - value2);
};
