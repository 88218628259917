/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { LayersState } from "../../../../actions/Layers/reducer";
import useConfig, { useTenantFormOptions } from "../../../../actions/Tenants/config/configHook";
import { RootState } from "../../../../reducers";
import { InvalidLayerModal } from "../../AddItem/components/InvalidLayerModal";
import AllocateLayerModal from "./AllocateLayerModal";
import CreateLayerRelationModal from "./CreateLayerRelationModal";
import { EditLayerButton } from "./EditLayer";

export const getLatestCheck = (checks, location: string | null = null, layer_id: string | null = null) => {
    const theChecks = checks
        .filter((i) => !location || (i.location === location))
        .filter((i) => !layer_id || (i.layer_id === layer_id))
        .sort((a, b) => a.test_id - b.test_id);
    return theChecks[theChecks.length - 1];
};

export function LayerMeta() {
    const layer = useSelector<RootState, LayersState["current"]>((state) => state.layers.current);
    const checks = useSelector<RootState, LayersState["checks"]>((state) => state.layers.checks);

    const latest_check = getLatestCheck(checks);
    const children = useSelector<RootState, LayersState["children"]>((state) => state.layers.children);
    const user = useSelector<RootState, any>((state) => state.auth.user);
    const form_options = useTenantFormOptions();
    const config = useConfig();
    const navigate = useNavigate();

    if (!layer || !layer.id) {
        return null;
    }

    const layer_type = config.get_layer_config(layer);

    if (!layer_type) {
        return null;
    }

    const reports = config.get_layer_pdf_reports(layer);
    return <div className="pt-4">
        <div css={css`min-height: 20rem;`}>
            {layer_type.meta_display.map((i, index) => <MetaRow key={index} label={i.label} value={i.set_value({
                config,
                layer,
                check: latest_check, // * Make sure to copy the fields you need with copy_check_to_layer_meta
                fruit: null, // * Fruit list is not available on overview screen
                children,
                form_options // * used to translate form values to labels
            }).display_component()} />)}
            <div className="d-flex flex-wrap justify-content-end pt-3">
                <CreateLayerRelationModal />
                {user.is_admin && <InvalidLayerModal layer={layer} />}

                <EditLayerButton layer_type={layer_type} />
                {layer_type.show_allocate_button && <AllocateLayerModal />}
            </div>
            {reports.length > 0 && <div className="d-flex flex-wrap justify-content-end pt-3">
                {reports.map((i, index) => {
                    if (i.use_specific_pdf_layout) {
                        return <div key={index} className="pe-2">
                            <Button color="primary" className="text-nowrap text-capitalize" onClick={() => navigate(`/layer/${layer.id}/report/${i.value}`)}>{i.text} report <FontAwesomeIcon icon={faFileExport} /></Button>
                        </div>;
                    }
                    const latestCheck = getLatestCheck(checks, i.value);
                    if (latestCheck) {
                        return <div key={index} className="pe-2">
                            <Button color="primary" className="text-nowrap text-capitalize" onClick={() => navigate(`/layer/${layer.id}/add-check/${latestCheck.test_id}/report`)}>{i.text} report <FontAwesomeIcon icon={faFileExport} /></Button>
                        </div>;
                    }
                    return null;
                })}
            </div>}
        </div>
    </div>;
}

export function MetaRow({ label, value }) {
    return <div className="d-sm-flex align-items-center py-1">
        <div className="text-muted pe-2" css={css`width: 15rem;`}>
            {label}
        </div>
        <div className="ms-auto text-end text-capitalize">
            {value}
        </div>
    </div>;
}

MetaRow.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.any,
};
