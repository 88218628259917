
export enum MimeType {
    Json= "application/json",
    Pdf= "application/pdf",
    Jpeg= "image/jpeg",
    Png= "image/png",
    Gif= "image/gif",
    Tiff= "image/tiff",
    Svg= "image/svg+xml",
    Bmp= "image/bmp",
    Webp= "image/webp",
    XIcon= "image/x-icon",
    Csv= "text/csv",
    Plain= "text/plain",
    Html= "text/html",
    Xml= "text/xml",
}

export enum ReportType {
    Intake = "intake",
    ART = "art",
    Reassessment = "reassessment",
    Temperature = "temperature",
}
