import { getStore } from "../../store";
import { convert_to_comma_seperated_list } from "../util";
import {
    CREATE, DELETE, GET, LIST,
    PATCH,
    UPDATE
} from "./actionsTypes";
import { defaultCheck } from "./constants";
import { formatCheck2Backend } from "./util";

const endpoint = "tests/";

export function listChecks(filter) {
    const state = getStore().getState();
    const currentFilter = state.checks.filter;
    filter = { ...currentFilter, ...filter };
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
                params: filter,
            },
        },
    };
}

export function resetChecks() {
    return {
        type: LIST.RESET,
    };
}

// *set showLoading to false when you just want to refresh the check
// *without showing the loading spinner (and unmounting numerous components)
export function getCheck(test_id, showLoading = true) {
    return {
        types: [showLoading ? GET.LOADING : `${GET.LOADING}~DoNotShowLoading`, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + test_id}/`,
            },
        },
    };
}

export function triggerCheckBusinessRules(test_id) {
    return {
        types: [`${GET.LOADING}~DoNotShowLoading`, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + test_id}/business-rules`,
            },
        },
    };
}

export function createCheck(data) {
    data = formatCheck2Backend(data);
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data,
            },
        },
    };
}

export function updateCheckState(data) {
    data = formatCheck2Backend(data);
    return {
        type: GET.SUCCESS,
        payload: { data },
    };
}
export function resetCheckState() {
    const data = { ...defaultCheck };
    return {
        type: GET.SUCCESS,
        payload: { data },
    };
}

// export function updateCheck(data) {
//     data = formatCheck2Backend(data);
//     return {
//         types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
//         payload: {
//             request: {
//                 method: "PUT",
//                 url: `${endpoint + data.test_id}/`,
//                 data,
//             },
//         },
//     };
// }

export function patchCheck(test_id, field, value, callApi = true) {

    // only update the field in the store
    if (!callApi) {
        return {
            type: PATCH.STATE,
            payload: { field, value },
        };
    }
    const data = {
        field,
        state_value: value,
        value: convert_to_comma_seperated_list(value)
    };
    return {
        types: [PATCH.LOADING, PATCH.SUCCESS, PATCH.ERROR],
        payload: {
            request: {
                method: "PATCH",
                url: `${endpoint + test_id}/`,
                data,
            },
        },
    };
}

// export function addCheckImage(data) {
//     return {
//         types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
//         payload: {
//             request: {
//                 method: "POST",
//                 url: `/images/check/${data.test_id}/add_image/`,
//                 data,
//             },
//         },
//     };
// }

// export function deleteCheckImage(data) {
//     return {
//         types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
//         payload: {
//             request: {
//                 method: "DELETE",
//                 url: `/images/check/${data.test_id}/delete_image/`,
//                 data,
//             },
//         },
//     };
// }

export function deleteCheck(test_id) {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint + test_id}/`,
            },
        },
    };
}

export function updatePressurePredictions(check, r) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint + check.test_id}/set_pressure_prediction/`,
                data: r,
            },
        },
    };
}


export function setAsCalibrationAvocados(test_id, calibration_stage, fruit_ids) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                no_toast: false,
                method: "POST",
                url: `${endpoint + test_id}/set_as_calibration/`,
                data: { calibration_stage, fruit_ids },
            },
        },
    };
}

