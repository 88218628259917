/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { Layer } from "../../../../../actions/Layers/constants";
import { find_category } from "../../../../../actions/Tenants/config/utils";


export const MetricCardComponents = {
    delay_on_arrival: MetricCardETA,
    transit_time: MetricCardTransitTime,
    unique_ggn: MetricCardUniqueGGN,
    fruit_age: MetricCardFruitAge,
    average_dry_matter: MetricCardAverageDryMatter,
    spread_harvest: MetricCardHarvestSpread,
    spread_harvest_packing: MetricCardSpreadHarvestPacking,
};

export interface MetricCardProps {
    title: string,
    children: any,
    color: string | null
    description?: any

}

interface CustomMetricCardProps {
    metric: {
        flags: object[],
        label: string,
        default_flag: string
    }
}

function getFlagColor(value, flags, default_flag) {
    const flag = find_category(value, flags);
    if (flag) {
        return flag.flag;
    }
    return default_flag;

}

export default function MetricCard({ title, children, color, description }: MetricCardProps) {
    const [modal, setModal] = useState(false);
    return (
        <>
            <div onClick={() => setModal(true)} css={css`border-radius:8px; width: 20%; `} className={`p-3 me-3 overflow-hidden  position-relative bg-white card-alert --flag-${color}`} >
                {description && <span css={css`position: absolute; top: 2%; right: 4%; opacity: 0.4;`}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </span>}
                <div className="d-flex align-items-center flex-wrap py-1 display-4 text-nowrap text-truncate">
                    {children}
                </div>
                <div className="text-secondary font-weight-bold text-truncate"><b>{title}</b></div>
            </div>
            {description && <Modal isOpen={modal} toggle={() => setModal(false)}>
                <ModalHeader toggle={() => setModal(false)}>Description</ModalHeader>
                <ModalBody>{description}</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            }
        </>
    );
}

function MetricCardUniqueGGN({ metric }: CustomMetricCardProps) {
    const children = useSelector<any, any>((state) => state.layers.children);
    const description = <span>This metric shows the count of unique Global Gap Numbers. The color indicates the performance level based on configured rules.</span>;

    const uniqueGlobalGapNumbers = new Set(children.results?.map((i) => i.global_gap_number));
    const uniqueGlobalGapNumbersCount = String(uniqueGlobalGapNumbers.size);
    if (!uniqueGlobalGapNumbersCount) {
        return null;
    }
    const flag = getFlagColor(uniqueGlobalGapNumbersCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueGlobalGapNumbersCount} </MetricCard>);
}

function MetricCardETA({ metric }: CustomMetricCardProps) {
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const description = <span>Displays the difference in days between the actual arrival and estimated arrival dates. Color reflects performance based on configured rules.</span>;

    const arrivalDate = dayjs(layer.arrival_date_intake || (new Date()));

    const ETA = dayjs(layer.estimated_date_of_arrival);

    if (!ETA.isValid()) {
        return null;
    }
    const diff = arrivalDate.diff(ETA, "days");
    const flag = getFlagColor(diff, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description} >{diff}</MetricCard>);

}

function MetricCardTransitTime({ metric }: CustomMetricCardProps) {
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const description = <span>Shows transit time in days from departure to arrival. If the shipment has not arrived yet, the current date is used in the calculation for arrival. Color indicates status according to configured decision rules.</span>;
    const arrivalDate = dayjs(layer.arrival_date_intake || (new Date()));


    const departure_date = dayjs(layer.departure_date);

    if (!departure_date.isValid()) {
        return null;
    }
    const diff = Math.max(arrivalDate.diff(departure_date, "days"), 0);
    const flag = getFlagColor(diff, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}>{diff}</MetricCard>);

}

function MetricCardAverageDryMatter({ metric }: CustomMetricCardProps) {
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const description = <span>Displays the average Dry Matter value from lab checks. Color indicates status according to configured decision rules.</span>;
    const dry_matter_number = Number(layer.lab_check_average_dry_matter);
    // check is the value is a number and bigger than zero
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(dry_matter_number) || dry_matter_number <= 0) {
        return null;
    }
    const flag = getFlagColor(layer.lab_check_average_dry_matter, metric.flags, metric.default_flag);
    return <MetricCard title={metric.label} color={flag} description={description}>{dry_matter_number?.toFixed(2)}</MetricCard>;
}

function MetricCardFruitAge({ metric }: CustomMetricCardProps) {
    const children = useSelector<any, any>((state) => state.layers.children);
    const description = <span>The fruit age is calculated from the oldest date of harvest to the arrival date. If the harvest date is unknown, the packing date is used instead. When the arrival date is not provided, the estimated date of arrival is used. The metric presents the minimum and maximum fruit ages, or a single value if they are identical. The color coding is determined by predefined decision rules for easy interpretation.</span>; // eslint-disable-line
    const fruitAges = new Set<number>();

    const layer = useSelector<any, Layer>((state) => state.layers.current);

    const arrivalDate = dayjs(layer.arrival_date_intake || layer.estimated_date_of_arrival);

    if (!arrivalDate.isValid()) {
        return null;
    }

    children.results?.forEach((result) => {
        let harvestDate = dayjs(result.harvest_date);

        if (!harvestDate.isValid()) {
            // If harvest date is not valid, we use packing date as proxy
            harvestDate = dayjs(result.packing_date);
            if (!harvestDate.isValid()) {
                // * nothing else we can do here
                return;
            }
        }
        const age = dayjs(arrivalDate).diff(harvestDate, "day");

        if (age > 0) {
            fruitAges.add(age);
        }
    });

    if (!fruitAges.size) {
        return null;
    }
    const ages = Array.from(fruitAges);
    const maxAge = Math.max(...ages);
    const minAge = Math.min(...ages);

    const flag = getFlagColor(maxAge, metric.flags, metric.default_flag);

    return (
        <MetricCard title={metric.label} color={flag} description={description}>
            {minAge !== maxAge ? `${minAge}-${maxAge}` : maxAge}
        </MetricCard>
    );
}

function MetricCardHarvestSpread({ metric }: CustomMetricCardProps) {
    const children = useSelector<any, any>((state) => state.layers.children);
    const description = <span>This metric calculates the biggest difference among the harvest dates. If there only one harvest date exists, then displays 0. The color coding reflects the assessment based on predefined decision rules.</span>;
    const dateStringSet = new Set<string>(children.results?.filter((result) => result.harvest_date).map((result) => result.harvest_date));
    const dateArray = Array.from(dateStringSet).map((date) => dayjs(date)).filter((date) => date && date.isValid());
    if (dateArray.length < 1) return null;
    const sortedDates = dateArray.sort((a, b) => a.diff(b, "days"));
    let differenceInDays = 0;
    const latestDate = sortedDates[sortedDates.length - 1];
    if (sortedDates.length > 1) {
        differenceInDays = latestDate.diff(sortedDates[0], "days");
    }
    const flag = getFlagColor(differenceInDays, metric.flags, metric.default_flag);

    return (
        <MetricCard title={metric.label} color={flag} description={description}>
            {differenceInDays}
        </MetricCard>
    );
}

function MetricCardSpreadHarvestPacking({ metric }: CustomMetricCardProps) {
    const children = useSelector<any, any>((state) => state.layers.children);
    const description = <span>This metric calculates the days between the harvest and packing dates. It displays the minimum and maximum spread of days to indicate the range. If only one spread value exists, that singular value is shown. The color coding reflects the assessment based on predefined decision rules.</span>;
    const spreads = new Set<number>();


    children.results?.forEach((result) => {
        const harvestDate = dayjs(result.harvest_date);
        const packingDate = dayjs(result.packing_date);

        if (packingDate.isValid() && harvestDate.isValid() && packingDate.isAfter(harvestDate)) {
            spreads.add(packingDate.diff(harvestDate, "day"));
        }
    });

    const spread = Array.from(spreads);
    if (!spread.length) {
        return null;
    }
    const maxSpread = Math.max(...spread);
    const minSpread = Math.min(...spread);

    const flag = getFlagColor(maxSpread, metric.flags, metric.default_flag);

    return (
        <MetricCard title={metric.label} color={flag} description={description}>
            {minSpread !== maxSpread ? `${minSpread}-${maxSpread}` : maxSpread}
        </MetricCard>
    );
}
