/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from "dayjs";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { applyDisplayFilterLayerFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../actions/Tenants/config/configHook";

import { FILTER_METHODS, listLayers, prepareParams, resetLayerList, updateFilter } from "../../../../actions/Layers/actions";
import { LAYER_TAB_CATEGORIES, LAYER_TABS, LayerFilter } from "../../../../actions/Layers/constants";
import { Tab } from "../../../../actions/Tenants/config/constantsTyped";
import { getFilledArrayOrDefault, isFilledArray } from "../../../../utils";
import useAuthorization from "../../../../utils/authorization";
import { callAvosApi } from "../../../../utils/useAvosApiHook";
import { useFruitTypes, useUserPreferences } from "../../../../utils/useUserPreferences";
import { useDebounce } from "../../../Forms/utils";
import { attributeIsSet } from "../../Tenants/Forms/FlagConfigForm";
import { QC_STATUS } from "../Layer/Report/QCStatusButton";
import Field from "./Field";
// Create context
interface SwitchTabContextProps {
    switchTab: (tab: any) => void;
    listLayersDebounced: (value: any) => void;
    tabs: Tab[];
    sameCategoryTabs: Tab[];
    activeTab?: Tab;
    active_tab?: string;
    tab_category?: string;
    fields: Field[];
    submitTabQuery: (query: any) => void;
    personalTabQuery: any;
    updatePersonalTabSettings: (key:string | object, value?: any) => void;
    personalTabSettings: PersonalTabSettings;
    normalizeUrl: () => void;
    selectedLayers: { [key: string]: boolean };
    setSelectedLayers: (value: any) => void;
    selectedLayersArray: string[];
    refreshTab: () => void;
}

export interface PersonalTabSettings {
    layer_type?: string;
    view_strategy?: string;
    max_days_in_facility?: string;
    planned_work_look_ahead_days?: string;
    show_layers_without_planned_work?: string;
    max_days_in_stage?: string;
    show_draft_reports?: string[];
    show_sent_reports?: string[];
}

const SwitchTabContext = createContext({
    switchTab: () => null,
    listLayersDebounced: () => null,
    tabs: [],
    sameCategoryTabs: [],
    fields: [],
    submitTabQuery: () => null,
    personalTabQuery: {},
    normalizeUrl: () => null,
    selectedLayers: {},
    setSelectedLayers: () => null,
    selectedLayersArray: [],
    personalTabSettings: {},
    updatePersonalTabSettings: () => null,
    refreshTab: () => null,
} as SwitchTabContextProps);


const isValidDayNumber = (value) => value === 0 || value > 0;


function getDefaultTabMetaFilter(tab: Tab) {
    const filter = {};
    if (isValidDayNumber(tab.max_days_in_facility)) {
        const arrival_date = dayjs().subtract(tab.max_days_in_facility as number, "day");
        filter["layer_meta.arrival_date_intake_date_from"] = {
            label: `Arrival date > ${arrival_date.format("DD-MM-YYYY")}`,
            field: "layer_meta.arrival_date_intake",
            optional: false,
            method: FILTER_METHODS.DATE_FROM,
            value: arrival_date.format("YYYY-MM-DD"),
        };
    }
    if (isValidDayNumber(tab.planned_work_look_ahead_days)) {
        const planned_check_date = dayjs().add(tab.planned_work_look_ahead_days as number, "day");
        filter["layer_meta.planned_check_date_date_to"] = {
            label: `Planned work date < ${planned_check_date.format("DD-MM-YYYY")}`,
            field: "layer_meta.planned_check_date",
            optional: false,
            method: FILTER_METHODS.DATE_TO,
            value: planned_check_date.format("YYYY-MM-DD"),
        };
    }
    if (isValidDayNumber(tab.max_days_in_stage)) {
        filter["latest_location.days_in_stage_max"] = {
            label: `Days in Stage ≤ ${tab.max_days_in_stage}`,
            field: "latest_location.days_in_stage",
            optional: false,
            method: FILTER_METHODS.NUMBER_RANGE_MAX,
            value: Number(tab.max_days_in_stage),
        };
    }
    if (isFilledArray(tab.show_draft_reports)) {
        filter["layer_meta.qc_status_draft_reports"] = {
            label: `Items with Draft reports`,
            field: tab.show_draft_reports,
            optional: false,
            editable: false,
            method: FILTER_METHODS.IN_ANY_KEY,
            value: [QC_STATUS.DRAFT],
        };
    }
    if (isFilledArray(tab.show_sent_reports)) {
        filter["layer_meta.qc_status_sent_reports"] = {
            label: `Items with Sent reports`,
            field: tab.show_sent_reports,
            optional: false,
            editable: false,
            method: FILTER_METHODS.IN_ANY_KEY,
            value: [QC_STATUS.SENT],
        };
    }
    if (attributeIsSet(tab.show_layers_without_planned_work)) {
        if (tab.show_layers_without_planned_work === "all") {
            filter["layer_meta.planned_check_date_is_set"] = null;
        } else {
            filter["layer_meta.planned_check_date_is_set"] = {
                label: tab.show_layers_without_planned_work === "only-filled" ? "Items with Planned work date" : "Items without Planned work date",
                field: "layer_meta.planned_check_date",
                optional: false,
                method: FILTER_METHODS.IS_SET,
                value: tab.show_layers_without_planned_work,
            };
        }
    }

    return filter;
}
// Provider component
export const SwitchTabProvider = ({ children }) => {
    const config = useConfig();
    const dispatch = useDispatch();
    const selectedFruitTypes = useFruitTypes();
    const params = useParams();
    const { active_tab } = params;
    const navigate = useNavigate();
    const auth = useAuthorization();
    const [tabItemCounts, setTabItemCounts] = useState({});
    const filter = useSelector<any, any>((state) => state.layers.filter);

    const [selectedLayers, setSelectedLayers] = useState({});
    const selectedLayersArray = Object.keys(selectedLayers).filter((key) => selectedLayers[key]);

    // * Keep track of personal query and settings per tab
    // * Settings translate into a query
    const [personalQueryMap, setPersonalQueryMap] = useState({} as { [tab: string]: any });
    const personalTabQuery = personalQueryMap[active_tab as string] || {};
    const [userPreferences, updatePreferences] = useUserPreferences();
    // * At this time user settings should be known and loaded
    const [personalTabSettingsMap, setPersonalTabSettings] = useState(userPreferences?.tabs || {} as {[tab: string]: PersonalTabSettings});
    const personalTabSettings = personalTabSettingsMap[active_tab as string] || {};


    // * Setting up tabs
    const tabs = config.stages.map((i) => ({
        ...i,
        title: i.text,
        tab_categories: getFilledArrayOrDefault(i.tab_categories, [LAYER_TAB_CATEGORIES.OPERATION]),
        subtitle: null,
        value: i.value,

        layer_type: personalTabSettingsMap[i.value]?.layer_type || i.layer_type,
        view_strategy: personalTabSettingsMap[i.value]?.view_strategy || i.view_strategy,

        // * Tab attributes that can be translated to a meta query with getDefaultTabMetaFilter
        max_days_in_facility: personalTabSettingsMap[i.value]?.max_days_in_facility || i.max_days_in_facility,
        planned_work_look_ahead_days: personalTabSettingsMap[i.value]?.planned_work_look_ahead_days || i.planned_work_look_ahead_days,
        max_days_in_stage: personalTabSettingsMap[i.value]?.max_days_in_stage || i.max_days_in_stage,
        show_draft_reports: personalTabSettingsMap[i.value]?.show_draft_reports || i.show_draft_reports,
        show_sent_reports: personalTabSettingsMap[i.value]?.show_sent_reports || i.show_sent_reports,
        show_layers_without_planned_work: personalTabSettingsMap[i.value]?.show_layers_without_planned_work || i.show_layers_without_planned_work,

        default_ordering: i.default_ordering,
        count: tabItemCounts[i.value],

    })).filter((i) => {
        if (isFilledArray(i.show_for_teams)) {
            return auth.userBelongsToOneOfTeams(i.show_for_teams);
        }
        if (isFilledArray(i.hide_for_teams)) {
            // * hide tab if user belongs to one of the teams
            return !auth.userBelongsToOneOfTeams(i.hide_for_teams);
        }
        return true;
    });

    const tab_category = params.tab_category || tabs?.[0]?.tab_categories?.[0] || LAYER_TAB_CATEGORIES.OPERATION;
    const sameCategoryTabs = tabs.filter((i) => i.tab_categories.includes(tab_category));
    const activeTab = tabs.find((i) => i.value === active_tab) || sameCategoryTabs?.[0] || tabs?.[0];


    // * Get fields for the active tab
    const getFields = (tab, selectedFruitTypes) => {
        return tab ? applyDisplayFilterLayerFields(tab?.layer_overview_fields || [], {
            fruit_type: getFilledArrayOrDefault(selectedFruitTypes).length === 1 ? selectedFruitTypes[0] : null, // * only enable fruit type filter on fields when exactly one fruit type is selected
            layer_type: tab?.layer_type
        }) : [];
    };

    const fields = useMemo(() => getFields(activeTab, selectedFruitTypes), [activeTab?.value, selectedFruitTypes]);

    // * Define functions for fetching layers
    const [listLayersDebounced] = useDebounce(
        (filter, extendFilter = true) => {
            dispatch(updateFilter(filter, extendFilter));
        },
        (filter, _ = null) => dispatch(listLayers(filter) as any).then((response) => updateTabItemCount(response.payload.data.count)),
        { debounceTime: 500, immediate: true }
    );

    const buildQueryAndlistLayers = (query) => {
        // * Did the user configured a filter on this tab?

        const { ordering, ...userMetaFilter } = query;

        // * Get default meta_filter for this tab
        const defaultTabFilter = getDefaultTabMetaFilter(activeTab);

        // * Make sure default filters can onle be overwritten by non-null values
        // * With other words...You can edit a default filter, but never completly remove it
        const meta_filter = Object.keys(userMetaFilter).reduce((acc, i) => {
            if (userMetaFilter[i]) {
                acc[i] = userMetaFilter[i];
            }
            return acc;
        }, defaultTabFilter);

        const fields = getFields(activeTab, selectedFruitTypes);

        const newFilter: LayerFilter = {
            stage: activeTab.filter_stages,
            assigned_team: activeTab.filter_teams,
            layer_type: activeTab.layer_type,
            view_strategy: activeTab.view_strategy,
            fruit_type: selectedFruitTypes,
            meta_filter,
            select_fields: getFilledArrayOrDefault(fields).flatMap((i: Field) => i.get_backend_fieldname()),
            offset: 0,
            limit: filter.limit || 25,
            ordering: ordering || activeTab.default_ordering || "check.created,DESC"
        };

        listLayersDebounced(newFilter, false);

    };

    // * Submit tab query from filtering modal
    const submitTabQuery = useCallback((query) => {
        if (!active_tab) return;
        // * Update the query for the active tab for later usage (when switching tabs)
        setPersonalQueryMap((prev) => ({ ...prev, [active_tab as string]: query }));
        buildQueryAndlistLayers(query);

        // * Navigate to the tab without the edit_filter parameter to prevent reopening the tab after a remount
        normalizeUrl();
    }, [active_tab, activeTab?.value]);

    const updateTabItemCount = useCallback((count) => {
        setTabItemCounts((prev) => ({ ...prev, [activeTab?.value]: count }));
    }, [activeTab?.value]);

    const updatePersonalTabSettings = useCallback((field, value = null) => {
        setPersonalTabSettings((prev) => {
            const newSettings = typeof field === "object" ? field : { ...prev[activeTab?.value as string], [field]: value };
            const tabs = ({ ...prev, [activeTab?.value as string]: newSettings });
            // * Store tab settings in preferences
            updatePreferences({
                tabs
            });
            return tabs;
        });
    }, [activeTab?.value, userPreferences]);

    const refreshTab = () => {
        if (!active_tab) return;
        const query = personalQueryMap[activeTab.value] || {};
        buildQueryAndlistLayers(query);
    };

    // * Effect when you change tab or fruit types
    useEffect(() => {
        dispatch(resetLayerList());
        setSelectedLayers({});
        if (activeTab?.value) {
            // * Special case for packing list and mailbox
            if ([LAYER_TABS.PACKING_LIST, LAYER_TABS.QC_MAILBOX].includes(activeTab.value)) {
                return;
            }
            const query = personalQueryMap[activeTab.value] || {};
            buildQueryAndlistLayers(query);

        }
    }, [activeTab?.value, selectedFruitTypes.join(",")]);


    //  * Effect to count items in tabs currently in view
    useEffect(() => {
        sameCategoryTabs.map((tab) => {
            // * Special case for packing list
            if (tab.value === LAYER_TABS.PACKING_LIST) {
                return callAvosApi(`/packing-list/`, { params: { limit: 0 } }).then((response) => {
                    setTabItemCounts((prev) => ({ ...prev, [tab.value]: response.data.count }));
                });
            }
            // * Special case for QC mailbox
            if (tab.value === LAYER_TABS.QC_MAILBOX) {
                return callAvosApi(`/notification/`, { params: { limit: 0 } }).then((response) => {
                    setTabItemCounts((prev) => ({ ...prev, [tab.value]: response.data.count }));
                });
            }


            // * Did the user configured a filter on this tab?
            const query = personalQueryMap[tab.value] || {};
            const { ordering, view_strategy, layer_type, ...userMetaFilter } = query;
            const _ = ordering;

            // * Get default meta_filter for this tab
            const defaultTabFilter = getDefaultTabMetaFilter(tab);

            const newFilter: LayerFilter = {
                stage: tab.filter_stages,
                assigned_team: tab.filter_teams,
                layer_type: layer_type || tab.layer_type,
                view_strategy: view_strategy || tab.view_strategy, // * SELF or AGGREGATE, I think we can drop this...
                offset: 0,
                ordering: "layer.created,DESC",
                select_fields: ["layer.id"], // * just select one field
                meta_filter: { ...defaultTabFilter, ...userMetaFilter },
                fruit_type: selectedFruitTypes,
                limit: 0
            };

            return callAvosApi(`/layers/`, {
                params: prepareParams(newFilter),
            }).then((response) => {
                setTabItemCounts((prev) => ({ ...prev, [tab.value]: response.data.count }));
            });
        });

    }, [sameCategoryTabs.map((i) => i.value).join(",")]);


    const switchTab = (tab) => navigate(`/layer/tab/${tab_category || tab.tab_categories[0]}/${tab.value}`);
    const normalizeUrl = useCallback(() => navigate(`/layer/tab/${tab_category}/${activeTab?.value}`), [activeTab?.category, activeTab?.value]);

    return (
        <SwitchTabContext.Provider value={{
            switchTab,
            listLayersDebounced,
            tabs,
            sameCategoryTabs,
            activeTab,
            active_tab,
            tab_category,
            fields,
            submitTabQuery,
            personalTabQuery,
            personalTabSettings,
            updatePersonalTabSettings,
            normalizeUrl,
            selectedLayers,
            setSelectedLayers,
            selectedLayersArray,
            refreshTab

        }}>
            {children}
        </SwitchTabContext.Provider>
    );
};


// Hook to use the context
export const useSwitchTabContext = () => {
    const context = useContext(SwitchTabContext);
    if (!context) {
        throw new Error("useSwitchTabContext must be used within a SwitchTabProvider");
    }
    return context as SwitchTabContextProps;
};
