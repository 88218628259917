/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PDFViewer, StyleSheet, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Button, Container
} from "reactstrap";
import { AnyAction } from "redux";
import {
    getLayer,
    resetLayerState
} from "../../../../../actions/Layers/actions";
import { CHECK_LOCATION, VIEW } from "../../../../../actions/Layers/constants";
import { format2Frontend } from "../../../../../actions/Layers/util";
import { Location } from "../../../../../actions/Navigation/constants";
import useConfig, { useTenantFormOptions } from "../../../../../actions/Tenants/config/configHook";
import { MinMaxAvg } from "../../../../../actions/Tenants/config/constantsTyped";
import { calculateMinMaxAvg } from "../../../../../actions/Tenants/config/utils";
import { ReportType } from "../../../../../constants/misc";
import { getFilledArrayOrDefault } from "../../../../../utils";
import useAuthorization from "../../../../../utils/authorization";
import useAvosApi, { callAvosApi } from "../../../../../utils/useAvosApiHook";
import { TenantDefect, useDefectsHook } from "../../../../Forms/useManagedOptionsHook";
import LoadingProgressBar from "../../../../Helper/LoadingProgressBar";
import { getFruitTableData } from "../../../AddItem/components/Summary/FruitTable";
import { SendReportButton } from "../SendReportButton";
import PDFArrivalCheck from "./PDFArrivalCheck";
import { PDFCheckFruitTables } from "./PDFCheckFruitTables";
import PDFContainerDefects from "./PDFContainerDefects";
import PDFDryMatterMinMaxAvgOverview from "./PDFDryMatterMinMaxAvgOverview";
import { PDFImages } from "./PDFImages";
import PDFLayerMeta from "./PDFLayerMeta";
import { PDFDocument, PDFPage } from "./PDFLayout";
import PDFPalletScoringOverview from "./PDFPalletScoringOverview";
import { PDFPallets } from "./PDFPallets";
import QCStatusButton from "./QCStatusButton";
import { prepareChecksForPDf } from "./utils";

export const PDFCountDownText = [
    {
        text: "Loading data...",
        seconds: 3
    },
    {
        text: "Fetching checks...",
        seconds: 3
    },
    {
        text: "List all the fruit...",
        seconds: 3
    },
    {
        text: "Fetching images...",
        seconds: 3
    },
    {
        text: "Rendering PDF...",
        seconds: 4
    },
    {
        text: "Fit images on page...",
        seconds: 4
    },
    {
        text: "Almost there...",
        seconds: 5
    },
    {
        text: "Finishing up...",
        seconds: 3
    }
];


export default function PDFIntakeReport() {
    const config = useConfig();
    const dispatch = useDispatch();
    const user = useSelector<any>((state) => state.auth.user);
    const layer = useSelector<any, any>((state) => state.layers.current);
    const tenantsIsLoading = useSelector<any>((state) => state.tenants.isLoading);
    const form_options = useTenantFormOptions();
    const [printChildren, setChildren] = useState<any>();
    const [printChecks, setPrintChecks] = useState<any>();
    const params = useParams();
    const layer_config = config.get_layer_config(layer);
    // * Report value must equal check.location
    const location = config.get_location(layer, { location: params.check_type });
    // const [defectChartImageInternal, setDefectChartImageInternal] = useState<string>();
    // const [defectChartImageExternal, setDefectChartImageExternal] = useState<string>();
    const all_defects = useDefectsHook(layer.fruit_type) as TenantDefect[];
    const [dryMatterMinMaxAvg, setDryMatterMinMaxAvg] = useState<MinMaxAvg>();

    const pdf_config = config.get_pdf_report(layer, params.check_type);
    const browse_history = useSelector<any, Location[]>((state) => state.navigation.browse_history);
    const lastBrowse = browse_history[browse_history.length - 1];
    const previousPath = lastBrowse?.pathname || `/layer/${params?.layer_id}`;


    const navigate = useNavigate();
    useEffect(() => {
        dispatch(resetLayerState());
        dispatch(getLayer(params.layer_id!) as unknown as AnyAction);
    }, [params.layer_id]);

    // We need a local state here becuase the pdf rendered cannot reach the react store
    useEffect(() => {
        if (!layer.id || (all_defects?.length || 0) === 0 || layer?.id !== params.layer_id) {
            return;
        }
        const fetchData = async () => {
            // fetching children
            try {
                const response = await callAvosApi(`/layers/${layer.id}/children`);
                const children = response.data;

                const checks_response = await callAvosApi(`/layers/${layer.id}/checks`, {
                    params: {
                        with_fruit: true,
                        with_meta: true,
                        check_location: params.check_type,
                        view_strategy: VIEW.CHILDREN
                    }
                });
                const checks = checks_response.data;

                // * get formatted intake checks, preload images and replace URLs with Blob URLs
                const formatted_checks = await prepareChecksForPDf(checks);

                // * Only use the pallets/children with intake check, set the check opbject on the pallet
                const children_with_intake = children.results
                    .map((l) => (
                        {
                            ...l,
                            latest_check: formatted_checks.find((i) => i.layer_id === l.id)
                        }
                    ))
                    .filter((l) => l.latest_check)
                    .map(format2Frontend);

                // * Populate the intake check with fruit table data
                const updatedChildren = children_with_intake
                    .map((child) => (
                        // we overwrite the latest check here because the `/tests/{test_id}` endpoint will also return all images and underlying avocados while the layer endpoint does not
                        {
                            ...child,
                            latest_check: {
                                ...child.latest_check,
                                label: `${child.label}`, // label for the images
                                table_data: getFruitTableData({ // this function generates the data for indivual fruit TODO: refactor to fields api
                                    layer: child,
                                    check: child.latest_check,
                                    fruits: child.latest_check.avocados,
                                    config,
                                    user,
                                    all_defects
                                }),
                                ...child.meta
                            }
                        }));

                setChildren(updatedChildren);
                setPrintChecks(updatedChildren.map((i) => i.latest_check));

                if (pdf_config?.show_dry_matter) {
                    const lab_check_response = await callAvosApi(`/layers/${layer.id}/checks`, { params: { view_strategy: VIEW.CHILDREN, check_location: CHECK_LOCATION.LAB_CHECK, with_fruit: true } });
                    if (lab_check_response.data) {
                        const dryMatterValues = lab_check_response.data
                            .flatMap((item) => item.avocados)
                            .filter((i) => i.manual_dry_matter)
                            .map((i) => i.manual_dry_matter);

                        setDryMatterMinMaxAvg(calculateMinMaxAvg(dryMatterValues, ""));
                    }
                }

            } catch (error) {
                toast.error("Could not prepare data for PDF report");
                console.error("Error fetching data:", error); // eslint-disable-line no-console
            }
        };

        fetchData();


    }, [layer.id, all_defects?.length]);

    const isLoading: boolean = (!layer_config || !printChecks || !printChildren || !pdf_config || tenantsIsLoading || all_defects.length === 0) as boolean;

    const default_options = {
        children_title: "Pallets",
        children_table_fields: [],
        children_check_summary_meta_fields: [],
        show_graphs: true, // by default is true for all tenants
        check_meta_fields: []
    };


    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            justifyContent: "space-between",
            height: "70vh",
            marginTop: "20px"
        },
        cell1: {
            width: "35%", // Adjust based on your layout
        },
        cell2: {
            width: "63%", // Adjust based on your layout
        },
        row2: {
            flexDirection: "row",
            justifyContent: "space-between",
            height: "50vh",
        },
    });

    const PrintReport = ({ onRender }) => {
        const { children_table_fields, children_title, children_note, logo_name, children_check_summary_meta_fields, check_meta_fields } = { ...default_options, ...pdf_config } as any;
        const defect_groups = location.defect_groups || [];
        const layer_fields = layer_config.meta_display.map((i) => i.set_value({
            config,
            layer,
            check: layer.latest_check, // * To be used in check tables and layer overview
            fruit: null, // * Fruit list is not available on overview screen
            children: { count: printChildren?.length || 0, results: getFilledArrayOrDefault(printChildren, []) }, // * children is only available for layer index your are viewing
            form_options // * used to translate form values to labels
        }));

        const children_layer_fields = printChildren.map((child) => {
            return children_check_summary_meta_fields.map((i) => i.clone().set_value({
                config,
                layer: child,
                check: child.latest_check, // * To be used in check tables and layer overview
                fruit: null,
                children: null,
                form_options // * used to translate form values to labels
            }));
        });

        const arrival_check_fileds = layer_config.show_arrival_check?.fields.map((i) => i.clone().set_value({
            config,
            layer,
            check: layer.latest_check, // * To be used in check tables and layer overview
            fruit: null,
            children: null,
            form_options // * used to translate form values to labels
        }));


        return (
            <>
                <PDFDocument onRender={onRender} title={`Intake report ${layer?.label}`} >
                    <PDFPage logo={logo_name} header_title="Intake check report">
                        <View style={styles.row} wrap={false}>
                            <View style={styles.cell1}>
                                <PDFLayerMeta layer={layer} fields={layer_fields} status={layer.intake_manual_flag ? layer.intake_manual_flag : layer.intake_status} />
                            </View>
                            <View style={styles.cell2}>
                                {defect_groups.find((item) => item.total_defects_title) && <View style={styles.row2} wrap={false}>
                                    {defect_groups.filter((item) => item.total_defects_title).map((group, index) => <PDFContainerDefects key={index} all_defects={all_defects} defect_group={group} printChecks={printChecks} />)}
                                </View>}
                                {pdf_config?.show_dry_matter && <PDFDryMatterMinMaxAvgOverview dryMatterMinMaxAvg={dryMatterMinMaxAvg} />}
                                <PDFPalletScoringOverview printChildren={printChildren} status={layer.intake_status} />
                            </View>
                            {/* {show_graphs && <View style={styles.cell2}>
                                <View>
                                    {defectChartImageInternal && <PDFChart chart={defectChartImageInternal} title={internal_defects_title} />}
                                </View>
                                <View>
                                    {defectChartImageExternal && <PDFChart chart={defectChartImageExternal} title={external_defects_title} />}
                                </View>
                            </View>} */}
                        </View>
                    </PDFPage>
                    {
                        arrival_check_fileds && <PDFPage>
                            <PDFArrivalCheck fields={arrival_check_fileds} />
                        </PDFPage>
                    }
                    {children_table_fields.length > 0 && <PDFPallets
                        fields={children_table_fields}
                        printChildren={printChildren}
                        config={config}
                        children_title={children_title}
                        form_options={form_options}
                    />}
                    {printChecks.map((i, index) => [<PDFCheckFruitTables
                        key={index + 0.1}
                        children_layer_fields={children_layer_fields}
                        header={i.table_data[0]}
                        children_note={i[children_note?.fieldname_getter]}
                        rows={i.table_data[1]}
                        footer={i.table_data[2]}
                        checkIndex={index}
                        all_defects={all_defects}
                        defect_groups={defect_groups}
                        check={i}
                        check_meta_fields={check_meta_fields}
                    />,
                    <PDFImages key={index + 0.2} images={i.all_images} />
                    ])}
                </PDFDocument>
            </>
        );
    };

    const [onSendReport] = useAvosApi("/notification/report-to-supplier", "POST", {}, {
        onSuccess: {
            message: "Report sending request received",
            showMessage: true
        }
    });
    const reportType = ReportType.Intake;
    const attachedReportsType = ReportType.Temperature;
    const [pdfBlob, setPdfBlob] = useState<Blob>();
    const onRender = async ({ blob }) => {
        if (!pdfBlob || pdfBlob.size !== blob.size) {
            setPdfBlob(blob);
        }
    };

    const auth = useAuthorization();
    const canSendReport = pdf_config?.show_send_email_button || auth.userBelongsToOneOfTeams(pdf_config?.teams_that_can_send);

    return <div className="px-3 py-4 bg-gray">
        <Container>
            <div className="d-flex justify-content-end align-items-center">
                <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                    <h3 className="mb-0 ">{layer_config?.text} {layer.label} </h3>
                </div>
                <div className="pb-2 pb-sm-0 d-flex align-items-center ms-auto">
                    <QCStatusButton field="qc_status_intake" pdf_config={pdf_config} />
                    {canSendReport && onSendReport && <SendReportButton layerId={layer.id} blob={pdfBlob} reportType={reportType} attachedReportsType={attachedReportsType} statusField="qc_status_intake" onSendReport={onSendReport as any} />}
                    <Button className="btn-close my-1" size="lg" onClick={() => navigate(previousPath)} ></Button>
                </div>
            </div>
        </Container>
        <Container className="py-5">
            <div css={css`margin:auto;width: 420mm;`} >
                {isLoading ? (
                    <LoadingProgressBar text={PDFCountDownText.slice(0, 4)} />
                ) : (
                    <div>
                        {!pdfBlob && <LoadingProgressBar text={PDFCountDownText} startIndex={4} />}
                        <PDFViewer style={{ width: "100%", height: "1200px", opacity: pdfBlob ? 1 : 0 }}>
                            <PrintReport onRender={onRender} />
                        </PDFViewer>
                    </div>
                )}
                {/* <div className="d-none">
                    <PlotlyBarchartHorizontal data={internalData} saveImageData={setDefectChartImageInternal} />
                    <PlotlyBarchartHorizontal data={externalData} saveImageData={setDefectChartImageExternal} />
                </div> */}
            </div>
        </Container>
    </div>;
}
