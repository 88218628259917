import { faBarcodeRead, faCameraRotate } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BarcodeFormat, DecodeHintType, MultiFormatReader, HTMLCanvasElementLuminanceSource, BinaryBitmap, HybridBinarizer } from "@zxing/library";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button, FormGroup, FormText, Input, Label } from "reactstrap";
import { BarcodeFilter } from "../../actions/Tenants/config/constantsTyped";
import { toast } from "../../utils/toast";
import { handlePatternValidation } from "../Forms/MetaForm";
// Interface for QR code scanner properties
export interface QRCodeScanProps {
    onScan: (decodedText: string) => void;
    barcode_filter?: BarcodeFilter | ((decodedText: string) => string);
}

// QR code scanner implementation using react-webcam and ZXing
export const QRCodeScan: React.FC<QRCodeScanProps> = ({ onScan, barcode_filter }) => {
    const webcamRef = useRef<Webcam>(null);
    const hints = useRef(new Map());
    const reader = useRef(new MultiFormatReader());
    const timerRef = useRef<any | null>(null);
    const [facingMode, setFacingMode] = useState("environment"); // Default to rear camera

    const { filter, formatsToSupport } = typeof barcode_filter === "object" ? barcode_filter : { filter: barcode_filter, formatsToSupport: undefined };
    console.log("formatsToSupport: ", formatsToSupport); // eslint-disable-line no-console
    // Set barcode formats and hints
    useEffect(() => {
        const formats = [
            BarcodeFormat.AZTEC,
            BarcodeFormat.CODABAR,
            BarcodeFormat.CODE_39,
            BarcodeFormat.CODE_93,
            BarcodeFormat.CODE_128,
            BarcodeFormat.DATA_MATRIX,
            BarcodeFormat.EAN_8,
            BarcodeFormat.EAN_13,
            BarcodeFormat.ITF,
            BarcodeFormat.MAXICODE,
            BarcodeFormat.PDF_417,
            BarcodeFormat.QR_CODE,
            BarcodeFormat.RSS_14,
            BarcodeFormat.RSS_EXPANDED,
            BarcodeFormat.UPC_A,
            BarcodeFormat.UPC_E,
            BarcodeFormat.UPC_EAN_EXTENSION];

        // Set the possible formats using DecodeHintType
        hints.current.set(DecodeHintType.POSSIBLE_FORMATS, formats);
        hints.current.set(DecodeHintType.PURE_BARCODE, true);

        // Add specific extensions for EAN or UPC barcodes (if you expect barcodes with extensions)
        hints.current.set(DecodeHintType.ALLOWED_EAN_EXTENSIONS, new Int32Array([2, 5]));

        // Set allowed lengths for fixed-length barcodes like ITF or Code 128
        hints.current.set(DecodeHintType.ALLOWED_LENGTHS, new Int32Array([8, 12, 14]));

        // Specify a character set (if your barcodes contain encoded data in specific character sets)
        hints.current.set(DecodeHintType.CHARACTER_SET, "UTF-8");

        // Assume a check digit for Code 39 barcodes
        hints.current.set(DecodeHintType.ASSUME_CODE_39_CHECK_DIGIT, true);

        // Use TRY_HARDER to make ZXing work harder on difficult-to-read barcodes
        hints.current.set(DecodeHintType.TRY_HARDER, true);

        reader.current.setHints(hints.current);
    }, []);

    const handleScan = useCallback(
        (decodedText: string) => {
            // Filter the decoded text
            const filteredValue = typeof filter === "function" ? filter(decodedText) : decodedText;

            if (filteredValue) {
                onScan(filteredValue);
            }
        },
        [onScan, filter]
    );

    const captureAndScan = useCallback(() => {
        if (!webcamRef.current) return;

        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                if (ctx) {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    const luminanceSource = new HTMLCanvasElementLuminanceSource(canvas);

                    const binaryBitmap = new BinaryBitmap(new HybridBinarizer(luminanceSource));

                    try {
                        const result = reader.current.decode(binaryBitmap);
                        const resultText = result.getText();
                        console.log("Decoded: ", resultText); // eslint-disable-line no-console

                        // Check if the result contains only alphanumeric characters
                        if (/^[a-zA-Z0-9/.:]+$/.test(resultText)) {
                            handleScan(resultText);
                        } else {
                            console.warn("Invalid barcode: contains non-alphanumeric characters"); // eslint-disable-line no-console
                        }

                    } catch (e) {
                        if (process.env.NODE_ENV === "development" && e instanceof Error) {
                            if (e && typeof e === "object" && e.name === "NotFoundException") {
                                console.debug("No QR code or barcode found, retrying..."); // eslint-disable-line no-console
                            } else {
                                console.error("An error occurred during decoding:", e); // eslint-disable-line no-console
                            }

                        }
                    }
                }
            };
        }
    }, [handleScan]);

    // Automatically start capturing if scanning is enabled
    useEffect(() => {
        if (timerRef.current) clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            // start timer
            // const date = new Date().getTime();
            captureAndScan(); // Trigger capture
            // const diff = new Date().getTime() - date;
            // console.log("Time to process", diff, "msec"); // eslint-disable-line no-console
        }, 20); // Calculate frame rate based on 50 frames per second
        return () => clearInterval(timerRef.current);
    }, [captureAndScan]);

    const onCameraError = (error) => {
        toast.error(`Failed to start camera: (${error.name}:${error.message}). Please stop any application that is using your camera.`, {
            toastId: "create-location",
            autoClose: 3500,
        });
    };

    const toggleFacingMode = useCallback(() => {
        setFacingMode((prevMode) => (prevMode === "environment" ? "user" : "environment"));
    }, []);

    return (
        <div style={{ position: "relative", width: "100%", display: "inline-block" }}>
            <Webcam
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode, width: 960, height: 720 }}
                width="100%"
                onUserMediaError={onCameraError}
                style={{ display: "block" }}
            />

            {/* Toggle Camera Button */}
            <button
                onClick={toggleFacingMode}
                style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    border: "none",
                    padding: "10px",
                    cursor: "pointer",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                }}
            >
                <FontAwesomeIcon icon={faCameraRotate} />
            </button>

            {/* Barcode Guideline Overlay */}
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "10%",
                    width: "80%",
                    height: "4px", // Thicker line
                    backgroundColor: "#00ff0069", // green  00ff0069 : "#ff0c0c80", // red
                    borderRadius: "1px", // Rounded edges
                    transform: "translateY(-50%)",
                }}
            ></div>
        </div>
    );
};

// Text QR code scanner properties
export interface TextQRcodeScannerProps {
    item: any;
    object: any;
    setValue: (name: string, value: any) => void;
    barcode_filter?: BarcodeFilter;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}


// Component for meta form input with QR code scanner
export const TextQRcodeScanner: React.FC<TextQRcodeScannerProps> = ({
    item,
    object,
    setValue,
    barcode_filter,
    onFocus
}) => {
    const [open, setOpen] = useState(false);

    const handleScan = useCallback(
        (result: string) => {
            setValue(item.name, result);
            setOpen(false);
        },
        [item.name, setValue]
    );

    const onBarcodeButtonClick = useCallback(() => {
        if (barcode_filter) {
            setOpen(!open);
        } else {
            alert("Misconfiguration: Barcode filter not set!"); // eslint-disable-line no-alert
        }
    }, [barcode_filter, open]);

    return (
        <FormGroup>
            <Label>{item.label}</Label>
            {open && <QRCodeScan
                onScan={handleScan}
                barcode_filter={barcode_filter}
            />}
            <FormGroup>
                <Input
                    type={item.type || "text"}
                    name={item.name}
                    id={item.name}
                    value={object[item.name]}
                    onChange={(e) => setValue(item.name, e.target.value)}
                    invalid={!handlePatternValidation(item, object)}
                    onFocus={onFocus}
                />
                {!handlePatternValidation(item, object) && item.invalid_hint && (
                    <FormText>{item.invalid_hint}</FormText>
                )}
                <Button
                    color="primary"
                    className="text-nowrap"
                    style={{
                        position: "relative",
                        float: "right",
                        top: "-2.4em",
                    }}
                    onClick={onBarcodeButtonClick}
                >
                    <FontAwesomeIcon icon={faBarcodeRead} />
                </Button>
            </FormGroup>
        </FormGroup>
    );
};

export default QRCodeScan;
